/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { AddBankLoanRequest } from "../model"
// @ts-ignore
import { AddBonusRequest } from "../model"
// @ts-ignore
import { AddGuaranteeRequest } from "../model"
// @ts-ignore
import { AddLoanRequest } from "../model"
// @ts-ignore
import { ApiBankLoan } from "../model"
// @ts-ignore
import { ApiBonusOperation } from "../model"
// @ts-ignore
import { ApiFinancialPlanResourcesImport } from "../model"
// @ts-ignore
import { ApiGuaranteeOperation } from "../model"
// @ts-ignore
import { ApiInvestmentOperation } from "../model"
// @ts-ignore
import { ApiOperations } from "../model"
// @ts-ignore
import { ApiOperationsImport } from "../model"
// @ts-ignore
import { BreakingTransitionResponse } from "../model"
// @ts-ignore
import { CheckTermsOfUseRequest } from "../model"
// @ts-ignore
import { NoubaLightProject } from "../model"
// @ts-ignore
import { ProductsByAssociation } from "../model"
// @ts-ignore
import { Project } from "../model"
// @ts-ignore
import { UpdateBankLoanRequest } from "../model"
// @ts-ignore
import { UpdateBonusRequest } from "../model"
// @ts-ignore
import { UpdateGuaranteeRequest } from "../model"
// @ts-ignore
import { UpdateLoanRequest } from "../model"
// @ts-ignore
import { UpdateProjectRequest } from "../model"
/**
 * ProjectResourceV2Api - axios parameter creator
 * @export
 */
export const ProjectResourceV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add bank loan
     * @param {number} projectId
     * @param {AddBankLoanRequest} addBankLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBankLoan: async (
      projectId: number,
      addBankLoanRequest: AddBankLoanRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("addBankLoan", "projectId", projectId)
      // verify required parameter 'addBankLoanRequest' is not null or undefined
      assertParamExists("addBankLoan", "addBankLoanRequest", addBankLoanRequest)
      const localVarPath = `/v2.0/projects/{projectId}/operations/bank-loan`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addBankLoanRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Add bonus
     * @param {number} projectId
     * @param {AddBonusRequest} addBonusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBonus: async (
      projectId: number,
      addBonusRequest: AddBonusRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("addBonus", "projectId", projectId)
      // verify required parameter 'addBonusRequest' is not null or undefined
      assertParamExists("addBonus", "addBonusRequest", addBonusRequest)
      const localVarPath = `/v2.0/projects/{projectId}/operations/bonus`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addBonusRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Add guarantee
     * @param {number} projectId
     * @param {AddGuaranteeRequest} addGuaranteeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addGuarantee: async (
      projectId: number,
      addGuaranteeRequest: AddGuaranteeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("addGuarantee", "projectId", projectId)
      // verify required parameter 'addGuaranteeRequest' is not null or undefined
      assertParamExists("addGuarantee", "addGuaranteeRequest", addGuaranteeRequest)
      const localVarPath = `/v2.0/projects/{projectId}/operations/guarantee`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addGuaranteeRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Add investment
     * @param {number} projectId
     * @param {AddLoanRequest} addLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLoan: async (
      projectId: number,
      addLoanRequest: AddLoanRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("addLoan", "projectId", projectId)
      // verify required parameter 'addLoanRequest' is not null or undefined
      assertParamExists("addLoan", "addLoanRequest", addLoanRequest)
      const localVarPath = `/v2.0/projects/{projectId}/operations/loan`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addLoanRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get note\'s terms of service
     * @param {number} projectId
     * @param {CheckTermsOfUseRequest} [checkTermsOfUseRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTermsOfUse: async (
      projectId: number,
      checkTermsOfUseRequest?: CheckTermsOfUseRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("checkTermsOfUse", "projectId", projectId)
      const localVarPath = `/v2.0/projects/{projectId}/conditions-utilisation`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkTermsOfUseRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Create a note on project
     * @param {number} projectId
     * @param {'EMERGENCE' | 'CREATION' | 'REPRISE' | 'DEVELOPMENT' | 'REBOUND' | 'SCALE'} [newLifeCycle]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    createNoteOnProject1: async (
      projectId: number,
      newLifeCycle?: "EMERGENCE" | "CREATION" | "REPRISE" | "DEVELOPMENT" | "REBOUND" | "SCALE",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("createNoteOnProject1", "projectId", projectId)
      const localVarPath = `/v2.0/projects/{projectId}`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (newLifeCycle !== undefined) {
        localVarQueryParameter["newLifeCycle"] = newLifeCycle
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Delete bank loan
     * @param {number} projectId
     * @param {string} bankLoanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBankLoan: async (
      projectId: number,
      bankLoanId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("deleteBankLoan", "projectId", projectId)
      // verify required parameter 'bankLoanId' is not null or undefined
      assertParamExists("deleteBankLoan", "bankLoanId", bankLoanId)
      const localVarPath = `/v2.0/projects/{projectId}/operations/bank-loans/{bankLoanId}`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"bankLoanId"}}`, encodeURIComponent(String(bankLoanId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Delete bonus
     * @param {number} projectId
     * @param {string} bonusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBonus: async (
      projectId: number,
      bonusId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("deleteBonus", "projectId", projectId)
      // verify required parameter 'bonusId' is not null or undefined
      assertParamExists("deleteBonus", "bonusId", bonusId)
      const localVarPath = `/v2.0/projects/{projectId}/operations/bonus/{bonusId}`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"bonusId"}}`, encodeURIComponent(String(bonusId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Delete guarantee
     * @param {number} projectId
     * @param {string} guaranteeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGuarantee: async (
      projectId: number,
      guaranteeId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("deleteGuarantee", "projectId", projectId)
      // verify required parameter 'guaranteeId' is not null or undefined
      assertParamExists("deleteGuarantee", "guaranteeId", guaranteeId)
      const localVarPath = `/v2.0/projects/{projectId}/operations/guarantees/{guaranteeId}`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"guaranteeId"}}`, encodeURIComponent(String(guaranteeId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Delete investment
     * @param {number} projectId
     * @param {string} investmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInvestment: async (
      projectId: number,
      investmentId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("deleteInvestment", "projectId", projectId)
      // verify required parameter 'investmentId' is not null or undefined
      assertParamExists("deleteInvestment", "investmentId", investmentId)
      const localVarPath = `/v2.0/projects/{projectId}/operations/investments/{investmentId}`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"investmentId"}}`, encodeURIComponent(String(investmentId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Look for projects
     * @param {string} [searchString] What to look for
     * @param {Set<'libelle' | 'structureNom' | 'structureNomCommercial' | 'porteursNomNaissance' | 'porteursNomUsage'>} [searchFields] Fields to filter on. null is equivalent to libelle+structureNom+porteursNomUsage
     * @param {boolean} [filterOnLoggedAdvisor] Add additional filter on current advisor
     * @param {string} [filterOnCommitteeDate] Add additional filter given committee date
     * @param {Set<string>} [filterOnProjectPhase] Project phases to filter on (default is no filter)
     * @param {Set<string>} [filterOnProjectActivityArea] Project activity areas to filter on (default is no filter)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProjects: async (
      searchString?: string,
      searchFields?: Set<
        | "libelle"
        | "structureNom"
        | "structureNomCommercial"
        | "porteursNomNaissance"
        | "porteursNomUsage"
      >,
      filterOnLoggedAdvisor?: boolean,
      filterOnCommitteeDate?: string,
      filterOnProjectPhase?: Set<string>,
      filterOnProjectActivityArea?: Set<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2.0/projects`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (searchString !== undefined) {
        localVarQueryParameter["searchString"] = searchString
      }

      if (searchFields) {
        localVarQueryParameter["searchFields"] = Array.from(searchFields)
      }

      if (filterOnLoggedAdvisor !== undefined) {
        localVarQueryParameter["filterOnLoggedAdvisor"] = filterOnLoggedAdvisor
      }

      if (filterOnCommitteeDate !== undefined) {
        localVarQueryParameter["filterOnCommitteeDate"] =
          (filterOnCommitteeDate as any) instanceof Date
            ? (filterOnCommitteeDate as any).toISOString().substr(0, 10)
            : filterOnCommitteeDate
      }

      if (filterOnProjectPhase) {
        localVarQueryParameter["filterOnProjectPhase"] = Array.from(filterOnProjectPhase)
      }

      if (filterOnProjectActivityArea) {
        localVarQueryParameter["filterOnProjectActivityArea"] = Array.from(
          filterOnProjectActivityArea
        )
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Check if lifecycle transition is authorized for project
     * @param {number} projectId
     * @param {'EMERGENCE' | 'CREATION' | 'REPRISE' | 'DEVELOPMENT' | 'REBOUND' | 'SCALE'} newLifeCycle
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getBreakingLifecycleTransition1: async (
      projectId: number,
      newLifeCycle: "EMERGENCE" | "CREATION" | "REPRISE" | "DEVELOPMENT" | "REBOUND" | "SCALE",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getBreakingLifecycleTransition1", "projectId", projectId)
      // verify required parameter 'newLifeCycle' is not null or undefined
      assertParamExists("getBreakingLifecycleTransition1", "newLifeCycle", newLifeCycle)
      const localVarPath = `/v2.0/projects/{projectId}/check-lifecycle-transition`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (newLifeCycle !== undefined) {
        localVarQueryParameter["newLifeCycle"] = newLifeCycle
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get operations by projectId
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperations: async (
      projectId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getOperations", "projectId", projectId)
      const localVarPath = `/v2.0/projects/{projectId}/operations`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get products by projectId and associationId
     * @param {number} projectId
     * @param {string} xRequestResourceForAtIdX corresponds to associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsByAssociation: async (
      projectId: number,
      xRequestResourceForAtIdX: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getProductsByAssociation", "projectId", projectId)
      // verify required parameter 'xRequestResourceForAtIdX' is not null or undefined
      assertParamExists(
        "getProductsByAssociation",
        "xRequestResourceForAtIdX",
        xRequestResourceForAtIdX
      )
      const localVarPath = `/v2.0/projects/{projectId}/products-by-association`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (xRequestResourceForAtIdX !== undefined && xRequestResourceForAtIdX !== null) {
        localVarHeaderParameter["x-request-resource-for-at-id-x"] = String(xRequestResourceForAtIdX)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get project by its nouba id and association id
     * @param {number} projectId
     * @param {string} xRequestResourceForAtIdX corresponds to associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject: async (
      projectId: number,
      xRequestResourceForAtIdX: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getProject", "projectId", projectId)
      // verify required parameter 'xRequestResourceForAtIdX' is not null or undefined
      assertParamExists("getProject", "xRequestResourceForAtIdX", xRequestResourceForAtIdX)
      const localVarPath = `/v2.0/projects/{projectId}`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (xRequestResourceForAtIdX !== undefined && xRequestResourceForAtIdX !== null) {
        localVarHeaderParameter["x-request-resource-for-at-id-x"] = String(xRequestResourceForAtIdX)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get recently viewed projects by advisor id
     * @param {string} [advisorId] advisor id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecentlyViewedProjectsByAdvisorId: async (
      advisorId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2.0/projects/recently-viewed`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (advisorId !== undefined) {
        localVarQueryParameter["advisorId"] = advisorId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Used by nouba to import financial plan resources
     * @summary Get financial plan resources import by projectId
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    importFinancialPlanResources1: async (
      projectId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("importFinancialPlanResources1", "projectId", projectId)
      const localVarPath = `/v2.0/projects/{projectId}/financial-plan-resources/import`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Used by nouba to import operations
     * @summary Get operation import by projectId
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    importOperations1: async (
      projectId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("importOperations1", "projectId", projectId)
      const localVarPath = `/v2.0/projects/{projectId}/operations/import`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get opened noteId for project
     * @param {number} projectId
     * @param {string} xRequestResourceForAtIdX corresponds to associationId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    openNoteOnProject: async (
      projectId: number,
      xRequestResourceForAtIdX: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("openNoteOnProject", "projectId", projectId)
      // verify required parameter 'xRequestResourceForAtIdX' is not null or undefined
      assertParamExists("openNoteOnProject", "xRequestResourceForAtIdX", xRequestResourceForAtIdX)
      const localVarPath = `/v2.0/projects/{projectId}/note`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (xRequestResourceForAtIdX !== undefined && xRequestResourceForAtIdX !== null) {
        localVarHeaderParameter["x-request-resource-for-at-id-x"] = String(xRequestResourceForAtIdX)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get note url for project
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    retrieveNoteUrlOnProject: async (
      projectId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("retrieveNoteUrlOnProject", "projectId", projectId)
      const localVarPath = `/v2.0/projects/{projectId}/note-url`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update bank loan
     * @param {number} projectId
     * @param {string} bankLoanId
     * @param {UpdateBankLoanRequest} updateBankLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBankLoan: async (
      projectId: number,
      bankLoanId: string,
      updateBankLoanRequest: UpdateBankLoanRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("updateBankLoan", "projectId", projectId)
      // verify required parameter 'bankLoanId' is not null or undefined
      assertParamExists("updateBankLoan", "bankLoanId", bankLoanId)
      // verify required parameter 'updateBankLoanRequest' is not null or undefined
      assertParamExists("updateBankLoan", "updateBankLoanRequest", updateBankLoanRequest)
      const localVarPath = `/v2.0/projects/{projectId}/operations/bank-loans/{bankLoanId}`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"bankLoanId"}}`, encodeURIComponent(String(bankLoanId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateBankLoanRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update bonus
     * @param {number} projectId
     * @param {string} bonusId
     * @param {UpdateBonusRequest} updateBonusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBonus: async (
      projectId: number,
      bonusId: string,
      updateBonusRequest: UpdateBonusRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("updateBonus", "projectId", projectId)
      // verify required parameter 'bonusId' is not null or undefined
      assertParamExists("updateBonus", "bonusId", bonusId)
      // verify required parameter 'updateBonusRequest' is not null or undefined
      assertParamExists("updateBonus", "updateBonusRequest", updateBonusRequest)
      const localVarPath = `/v2.0/projects/{projectId}/operations/bonuses/{bonusId}`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"bonusId"}}`, encodeURIComponent(String(bonusId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateBonusRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update guarantee
     * @param {number} projectId
     * @param {string} guaranteeId
     * @param {UpdateGuaranteeRequest} updateGuaranteeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuarantee: async (
      projectId: number,
      guaranteeId: string,
      updateGuaranteeRequest: UpdateGuaranteeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("updateGuarantee", "projectId", projectId)
      // verify required parameter 'guaranteeId' is not null or undefined
      assertParamExists("updateGuarantee", "guaranteeId", guaranteeId)
      // verify required parameter 'updateGuaranteeRequest' is not null or undefined
      assertParamExists("updateGuarantee", "updateGuaranteeRequest", updateGuaranteeRequest)
      const localVarPath = `/v2.0/projects/{projectId}/operations/guarantees/{guaranteeId}`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"guaranteeId"}}`, encodeURIComponent(String(guaranteeId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateGuaranteeRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update investment
     * @param {number} projectId
     * @param {string} loanId
     * @param {UpdateLoanRequest} updateLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLoan: async (
      projectId: number,
      loanId: string,
      updateLoanRequest: UpdateLoanRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("updateLoan", "projectId", projectId)
      // verify required parameter 'loanId' is not null or undefined
      assertParamExists("updateLoan", "loanId", loanId)
      // verify required parameter 'updateLoanRequest' is not null or undefined
      assertParamExists("updateLoan", "updateLoanRequest", updateLoanRequest)
      const localVarPath = `/v2.0/projects/{projectId}/operations/loan/{loanId}`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateLoanRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update nouba project
     * @param {number} projectId
     * @param {UpdateProjectRequest} updateProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject: async (
      projectId: number,
      updateProjectRequest: UpdateProjectRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("updateProject", "projectId", projectId)
      // verify required parameter 'updateProjectRequest' is not null or undefined
      assertParamExists("updateProject", "updateProjectRequest", updateProjectRequest)
      const localVarPath = `/v2.0/projects/{projectId}`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProjectRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ProjectResourceV2Api - functional programming interface
 * @export
 */
export const ProjectResourceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProjectResourceV2ApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Add bank loan
     * @param {number} projectId
     * @param {AddBankLoanRequest} addBankLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addBankLoan(
      projectId: number,
      addBankLoanRequest: AddBankLoanRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addBankLoan(
        projectId,
        addBankLoanRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Add bonus
     * @param {number} projectId
     * @param {AddBonusRequest} addBonusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addBonus(
      projectId: number,
      addBonusRequest: AddBonusRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addBonus(
        projectId,
        addBonusRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Add guarantee
     * @param {number} projectId
     * @param {AddGuaranteeRequest} addGuaranteeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addGuarantee(
      projectId: number,
      addGuaranteeRequest: AddGuaranteeRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addGuarantee(
        projectId,
        addGuaranteeRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Add investment
     * @param {number} projectId
     * @param {AddLoanRequest} addLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addLoan(
      projectId: number,
      addLoanRequest: AddLoanRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addLoan(
        projectId,
        addLoanRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get note\'s terms of service
     * @param {number} projectId
     * @param {CheckTermsOfUseRequest} [checkTermsOfUseRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkTermsOfUse(
      projectId: number,
      checkTermsOfUseRequest?: CheckTermsOfUseRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkTermsOfUse(
        projectId,
        checkTermsOfUseRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Create a note on project
     * @param {number} projectId
     * @param {'EMERGENCE' | 'CREATION' | 'REPRISE' | 'DEVELOPMENT' | 'REBOUND' | 'SCALE'} [newLifeCycle]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async createNoteOnProject1(
      projectId: number,
      newLifeCycle?: "EMERGENCE" | "CREATION" | "REPRISE" | "DEVELOPMENT" | "REBOUND" | "SCALE",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createNoteOnProject1(
        projectId,
        newLifeCycle,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Delete bank loan
     * @param {number} projectId
     * @param {string} bankLoanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBankLoan(
      projectId: number,
      bankLoanId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankLoan(
        projectId,
        bankLoanId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Delete bonus
     * @param {number} projectId
     * @param {string} bonusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBonus(
      projectId: number,
      bonusId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBonus(
        projectId,
        bonusId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Delete guarantee
     * @param {number} projectId
     * @param {string} guaranteeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteGuarantee(
      projectId: number,
      guaranteeId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGuarantee(
        projectId,
        guaranteeId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Delete investment
     * @param {number} projectId
     * @param {string} investmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteInvestment(
      projectId: number,
      investmentId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvestment(
        projectId,
        investmentId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Look for projects
     * @param {string} [searchString] What to look for
     * @param {Set<'libelle' | 'structureNom' | 'structureNomCommercial' | 'porteursNomNaissance' | 'porteursNomUsage'>} [searchFields] Fields to filter on. null is equivalent to libelle+structureNom+porteursNomUsage
     * @param {boolean} [filterOnLoggedAdvisor] Add additional filter on current advisor
     * @param {string} [filterOnCommitteeDate] Add additional filter given committee date
     * @param {Set<string>} [filterOnProjectPhase] Project phases to filter on (default is no filter)
     * @param {Set<string>} [filterOnProjectActivityArea] Project activity areas to filter on (default is no filter)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findProjects(
      searchString?: string,
      searchFields?: Set<
        | "libelle"
        | "structureNom"
        | "structureNomCommercial"
        | "porteursNomNaissance"
        | "porteursNomUsage"
      >,
      filterOnLoggedAdvisor?: boolean,
      filterOnCommitteeDate?: string,
      filterOnProjectPhase?: Set<string>,
      filterOnProjectActivityArea?: Set<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoubaLightProject>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findProjects(
        searchString,
        searchFields,
        filterOnLoggedAdvisor,
        filterOnCommitteeDate,
        filterOnProjectPhase,
        filterOnProjectActivityArea,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Check if lifecycle transition is authorized for project
     * @param {number} projectId
     * @param {'EMERGENCE' | 'CREATION' | 'REPRISE' | 'DEVELOPMENT' | 'REBOUND' | 'SCALE'} newLifeCycle
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getBreakingLifecycleTransition1(
      projectId: number,
      newLifeCycle: "EMERGENCE" | "CREATION" | "REPRISE" | "DEVELOPMENT" | "REBOUND" | "SCALE",
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BreakingTransitionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBreakingLifecycleTransition1(
        projectId,
        newLifeCycle,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get operations by projectId
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOperations(
      projectId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOperations>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOperations(projectId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get products by projectId and associationId
     * @param {number} projectId
     * @param {string} xRequestResourceForAtIdX corresponds to associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductsByAssociation(
      projectId: number,
      xRequestResourceForAtIdX: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductsByAssociation>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsByAssociation(
        projectId,
        xRequestResourceForAtIdX,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get project by its nouba id and association id
     * @param {number} projectId
     * @param {string} xRequestResourceForAtIdX corresponds to associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProject(
      projectId: number,
      xRequestResourceForAtIdX: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(
        projectId,
        xRequestResourceForAtIdX,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get recently viewed projects by advisor id
     * @param {string} [advisorId] advisor id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecentlyViewedProjectsByAdvisorId(
      advisorId?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoubaLightProject>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRecentlyViewedProjectsByAdvisorId(advisorId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Used by nouba to import financial plan resources
     * @summary Get financial plan resources import by projectId
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async importFinancialPlanResources1(
      projectId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFinancialPlanResourcesImport>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importFinancialPlanResources1(
        projectId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Used by nouba to import operations
     * @summary Get operation import by projectId
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async importOperations1(
      projectId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOperationsImport>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importOperations1(
        projectId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get opened noteId for project
     * @param {number} projectId
     * @param {string} xRequestResourceForAtIdX corresponds to associationId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async openNoteOnProject(
      projectId: number,
      xRequestResourceForAtIdX: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.openNoteOnProject(
        projectId,
        xRequestResourceForAtIdX,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get note url for project
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async retrieveNoteUrlOnProject(
      projectId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveNoteUrlOnProject(
        projectId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update bank loan
     * @param {number} projectId
     * @param {string} bankLoanId
     * @param {UpdateBankLoanRequest} updateBankLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBankLoan(
      projectId: number,
      bankLoanId: string,
      updateBankLoanRequest: UpdateBankLoanRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiBankLoan>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBankLoan(
        projectId,
        bankLoanId,
        updateBankLoanRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update bonus
     * @param {number} projectId
     * @param {string} bonusId
     * @param {UpdateBonusRequest} updateBonusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBonus(
      projectId: number,
      bonusId: string,
      updateBonusRequest: UpdateBonusRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiBonusOperation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBonus(
        projectId,
        bonusId,
        updateBonusRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update guarantee
     * @param {number} projectId
     * @param {string} guaranteeId
     * @param {UpdateGuaranteeRequest} updateGuaranteeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGuarantee(
      projectId: number,
      guaranteeId: string,
      updateGuaranteeRequest: UpdateGuaranteeRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGuaranteeOperation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGuarantee(
        projectId,
        guaranteeId,
        updateGuaranteeRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update investment
     * @param {number} projectId
     * @param {string} loanId
     * @param {UpdateLoanRequest} updateLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLoan(
      projectId: number,
      loanId: string,
      updateLoanRequest: UpdateLoanRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiInvestmentOperation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateLoan(
        projectId,
        loanId,
        updateLoanRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update nouba project
     * @param {number} projectId
     * @param {UpdateProjectRequest} updateProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProject(
      projectId: number,
      updateProjectRequest: UpdateProjectRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(
        projectId,
        updateProjectRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * ProjectResourceV2Api - factory interface
 * @export
 */
export const ProjectResourceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ProjectResourceV2ApiFp(configuration)
  return {
    /**
     *
     * @summary Add bank loan
     * @param {number} projectId
     * @param {AddBankLoanRequest} addBankLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBankLoan(
      projectId: number,
      addBankLoanRequest: AddBankLoanRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .addBankLoan(projectId, addBankLoanRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Add bonus
     * @param {number} projectId
     * @param {AddBonusRequest} addBonusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBonus(
      projectId: number,
      addBonusRequest: AddBonusRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .addBonus(projectId, addBonusRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Add guarantee
     * @param {number} projectId
     * @param {AddGuaranteeRequest} addGuaranteeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addGuarantee(
      projectId: number,
      addGuaranteeRequest: AddGuaranteeRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .addGuarantee(projectId, addGuaranteeRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Add investment
     * @param {number} projectId
     * @param {AddLoanRequest} addLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLoan(
      projectId: number,
      addLoanRequest: AddLoanRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .addLoan(projectId, addLoanRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get note\'s terms of service
     * @param {number} projectId
     * @param {CheckTermsOfUseRequest} [checkTermsOfUseRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTermsOfUse(
      projectId: number,
      checkTermsOfUseRequest?: CheckTermsOfUseRequest,
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .checkTermsOfUse(projectId, checkTermsOfUseRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create a note on project
     * @param {number} projectId
     * @param {'EMERGENCE' | 'CREATION' | 'REPRISE' | 'DEVELOPMENT' | 'REBOUND' | 'SCALE'} [newLifeCycle]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    createNoteOnProject1(
      projectId: number,
      newLifeCycle?: "EMERGENCE" | "CREATION" | "REPRISE" | "DEVELOPMENT" | "REBOUND" | "SCALE",
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .createNoteOnProject1(projectId, newLifeCycle, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete bank loan
     * @param {number} projectId
     * @param {string} bankLoanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBankLoan(projectId: number, bankLoanId: string, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .deleteBankLoan(projectId, bankLoanId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete bonus
     * @param {number} projectId
     * @param {string} bonusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBonus(projectId: number, bonusId: string, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .deleteBonus(projectId, bonusId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete guarantee
     * @param {number} projectId
     * @param {string} guaranteeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGuarantee(projectId: number, guaranteeId: string, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .deleteGuarantee(projectId, guaranteeId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete investment
     * @param {number} projectId
     * @param {string} investmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInvestment(
      projectId: number,
      investmentId: string,
      options?: any
    ): AxiosPromise<boolean> {
      return localVarFp
        .deleteInvestment(projectId, investmentId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Look for projects
     * @param {string} [searchString] What to look for
     * @param {Set<'libelle' | 'structureNom' | 'structureNomCommercial' | 'porteursNomNaissance' | 'porteursNomUsage'>} [searchFields] Fields to filter on. null is equivalent to libelle+structureNom+porteursNomUsage
     * @param {boolean} [filterOnLoggedAdvisor] Add additional filter on current advisor
     * @param {string} [filterOnCommitteeDate] Add additional filter given committee date
     * @param {Set<string>} [filterOnProjectPhase] Project phases to filter on (default is no filter)
     * @param {Set<string>} [filterOnProjectActivityArea] Project activity areas to filter on (default is no filter)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProjects(
      searchString?: string,
      searchFields?: Set<
        | "libelle"
        | "structureNom"
        | "structureNomCommercial"
        | "porteursNomNaissance"
        | "porteursNomUsage"
      >,
      filterOnLoggedAdvisor?: boolean,
      filterOnCommitteeDate?: string,
      filterOnProjectPhase?: Set<string>,
      filterOnProjectActivityArea?: Set<string>,
      options?: any
    ): AxiosPromise<Array<NoubaLightProject>> {
      return localVarFp
        .findProjects(
          searchString,
          searchFields,
          filterOnLoggedAdvisor,
          filterOnCommitteeDate,
          filterOnProjectPhase,
          filterOnProjectActivityArea,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Check if lifecycle transition is authorized for project
     * @param {number} projectId
     * @param {'EMERGENCE' | 'CREATION' | 'REPRISE' | 'DEVELOPMENT' | 'REBOUND' | 'SCALE'} newLifeCycle
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getBreakingLifecycleTransition1(
      projectId: number,
      newLifeCycle: "EMERGENCE" | "CREATION" | "REPRISE" | "DEVELOPMENT" | "REBOUND" | "SCALE",
      options?: any
    ): AxiosPromise<BreakingTransitionResponse> {
      return localVarFp
        .getBreakingLifecycleTransition1(projectId, newLifeCycle, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get operations by projectId
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperations(projectId: number, options?: any): AxiosPromise<ApiOperations> {
      return localVarFp.getOperations(projectId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get products by projectId and associationId
     * @param {number} projectId
     * @param {string} xRequestResourceForAtIdX corresponds to associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsByAssociation(
      projectId: number,
      xRequestResourceForAtIdX: string,
      options?: any
    ): AxiosPromise<Array<ProductsByAssociation>> {
      return localVarFp
        .getProductsByAssociation(projectId, xRequestResourceForAtIdX, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get project by its nouba id and association id
     * @param {number} projectId
     * @param {string} xRequestResourceForAtIdX corresponds to associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject(
      projectId: number,
      xRequestResourceForAtIdX: string,
      options?: any
    ): AxiosPromise<Project> {
      return localVarFp
        .getProject(projectId, xRequestResourceForAtIdX, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get recently viewed projects by advisor id
     * @param {string} [advisorId] advisor id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecentlyViewedProjectsByAdvisorId(
      advisorId?: string,
      options?: any
    ): AxiosPromise<Array<NoubaLightProject>> {
      return localVarFp
        .getRecentlyViewedProjectsByAdvisorId(advisorId, options)
        .then(request => request(axios, basePath))
    },
    /**
     * Used by nouba to import financial plan resources
     * @summary Get financial plan resources import by projectId
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    importFinancialPlanResources1(
      projectId: number,
      options?: any
    ): AxiosPromise<ApiFinancialPlanResourcesImport> {
      return localVarFp
        .importFinancialPlanResources1(projectId, options)
        .then(request => request(axios, basePath))
    },
    /**
     * Used by nouba to import operations
     * @summary Get operation import by projectId
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    importOperations1(projectId: number, options?: any): AxiosPromise<ApiOperationsImport> {
      return localVarFp
        .importOperations1(projectId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get opened noteId for project
     * @param {number} projectId
     * @param {string} xRequestResourceForAtIdX corresponds to associationId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    openNoteOnProject(
      projectId: number,
      xRequestResourceForAtIdX: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .openNoteOnProject(projectId, xRequestResourceForAtIdX, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get note url for project
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    retrieveNoteUrlOnProject(projectId: number, options?: any): AxiosPromise<string> {
      return localVarFp
        .retrieveNoteUrlOnProject(projectId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update bank loan
     * @param {number} projectId
     * @param {string} bankLoanId
     * @param {UpdateBankLoanRequest} updateBankLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBankLoan(
      projectId: number,
      bankLoanId: string,
      updateBankLoanRequest: UpdateBankLoanRequest,
      options?: any
    ): AxiosPromise<ApiBankLoan> {
      return localVarFp
        .updateBankLoan(projectId, bankLoanId, updateBankLoanRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update bonus
     * @param {number} projectId
     * @param {string} bonusId
     * @param {UpdateBonusRequest} updateBonusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBonus(
      projectId: number,
      bonusId: string,
      updateBonusRequest: UpdateBonusRequest,
      options?: any
    ): AxiosPromise<ApiBonusOperation> {
      return localVarFp
        .updateBonus(projectId, bonusId, updateBonusRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update guarantee
     * @param {number} projectId
     * @param {string} guaranteeId
     * @param {UpdateGuaranteeRequest} updateGuaranteeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuarantee(
      projectId: number,
      guaranteeId: string,
      updateGuaranteeRequest: UpdateGuaranteeRequest,
      options?: any
    ): AxiosPromise<ApiGuaranteeOperation> {
      return localVarFp
        .updateGuarantee(projectId, guaranteeId, updateGuaranteeRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update investment
     * @param {number} projectId
     * @param {string} loanId
     * @param {UpdateLoanRequest} updateLoanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLoan(
      projectId: number,
      loanId: string,
      updateLoanRequest: UpdateLoanRequest,
      options?: any
    ): AxiosPromise<ApiInvestmentOperation> {
      return localVarFp
        .updateLoan(projectId, loanId, updateLoanRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update nouba project
     * @param {number} projectId
     * @param {UpdateProjectRequest} updateProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(
      projectId: number,
      updateProjectRequest: UpdateProjectRequest,
      options?: any
    ): AxiosPromise<Project> {
      return localVarFp
        .updateProject(projectId, updateProjectRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for addBankLoan operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiAddBankLoanRequest
 */
export interface ProjectResourceV2ApiAddBankLoanRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiAddBankLoan
   */
  readonly projectId: number

  /**
   *
   * @type {AddBankLoanRequest}
   * @memberof ProjectResourceV2ApiAddBankLoan
   */
  readonly addBankLoanRequest: AddBankLoanRequest
}

/**
 * Request parameters for addBonus operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiAddBonusRequest
 */
export interface ProjectResourceV2ApiAddBonusRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiAddBonus
   */
  readonly projectId: number

  /**
   *
   * @type {AddBonusRequest}
   * @memberof ProjectResourceV2ApiAddBonus
   */
  readonly addBonusRequest: AddBonusRequest
}

/**
 * Request parameters for addGuarantee operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiAddGuaranteeRequest
 */
export interface ProjectResourceV2ApiAddGuaranteeRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiAddGuarantee
   */
  readonly projectId: number

  /**
   *
   * @type {AddGuaranteeRequest}
   * @memberof ProjectResourceV2ApiAddGuarantee
   */
  readonly addGuaranteeRequest: AddGuaranteeRequest
}

/**
 * Request parameters for addLoan operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiAddLoanRequest
 */
export interface ProjectResourceV2ApiAddLoanRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiAddLoan
   */
  readonly projectId: number

  /**
   *
   * @type {AddLoanRequest}
   * @memberof ProjectResourceV2ApiAddLoan
   */
  readonly addLoanRequest: AddLoanRequest
}

/**
 * Request parameters for checkTermsOfUse operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiCheckTermsOfUseRequest
 */
export interface ProjectResourceV2ApiCheckTermsOfUseRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiCheckTermsOfUse
   */
  readonly projectId: number

  /**
   *
   * @type {CheckTermsOfUseRequest}
   * @memberof ProjectResourceV2ApiCheckTermsOfUse
   */
  readonly checkTermsOfUseRequest?: CheckTermsOfUseRequest
}

/**
 * Request parameters for createNoteOnProject1 operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiCreateNoteOnProject1Request
 */
export interface ProjectResourceV2ApiCreateNoteOnProject1Request {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiCreateNoteOnProject1
   */
  readonly projectId: number

  /**
   *
   * @type {'EMERGENCE' | 'CREATION' | 'REPRISE' | 'DEVELOPMENT' | 'REBOUND' | 'SCALE'}
   * @memberof ProjectResourceV2ApiCreateNoteOnProject1
   */
  readonly newLifeCycle?: "EMERGENCE" | "CREATION" | "REPRISE" | "DEVELOPMENT" | "REBOUND" | "SCALE"
}

/**
 * Request parameters for deleteBankLoan operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiDeleteBankLoanRequest
 */
export interface ProjectResourceV2ApiDeleteBankLoanRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiDeleteBankLoan
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof ProjectResourceV2ApiDeleteBankLoan
   */
  readonly bankLoanId: string
}

/**
 * Request parameters for deleteBonus operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiDeleteBonusRequest
 */
export interface ProjectResourceV2ApiDeleteBonusRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiDeleteBonus
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof ProjectResourceV2ApiDeleteBonus
   */
  readonly bonusId: string
}

/**
 * Request parameters for deleteGuarantee operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiDeleteGuaranteeRequest
 */
export interface ProjectResourceV2ApiDeleteGuaranteeRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiDeleteGuarantee
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof ProjectResourceV2ApiDeleteGuarantee
   */
  readonly guaranteeId: string
}

/**
 * Request parameters for deleteInvestment operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiDeleteInvestmentRequest
 */
export interface ProjectResourceV2ApiDeleteInvestmentRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiDeleteInvestment
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof ProjectResourceV2ApiDeleteInvestment
   */
  readonly investmentId: string
}

/**
 * Request parameters for findProjects operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiFindProjectsRequest
 */
export interface ProjectResourceV2ApiFindProjectsRequest {
  /**
   * What to look for
   * @type {string}
   * @memberof ProjectResourceV2ApiFindProjects
   */
  readonly searchString?: string

  /**
   * Fields to filter on. null is equivalent to libelle+structureNom+porteursNomUsage
   * @type {Set<'libelle' | 'structureNom' | 'structureNomCommercial' | 'porteursNomNaissance' | 'porteursNomUsage'>}
   * @memberof ProjectResourceV2ApiFindProjects
   */
  readonly searchFields?: Set<
    | "libelle"
    | "structureNom"
    | "structureNomCommercial"
    | "porteursNomNaissance"
    | "porteursNomUsage"
  >

  /**
   * Add additional filter on current advisor
   * @type {boolean}
   * @memberof ProjectResourceV2ApiFindProjects
   */
  readonly filterOnLoggedAdvisor?: boolean

  /**
   * Add additional filter given committee date
   * @type {string}
   * @memberof ProjectResourceV2ApiFindProjects
   */
  readonly filterOnCommitteeDate?: string

  /**
   * Project phases to filter on (default is no filter)
   * @type {Set<string>}
   * @memberof ProjectResourceV2ApiFindProjects
   */
  readonly filterOnProjectPhase?: Set<string>

  /**
   * Project activity areas to filter on (default is no filter)
   * @type {Set<string>}
   * @memberof ProjectResourceV2ApiFindProjects
   */
  readonly filterOnProjectActivityArea?: Set<string>
}

/**
 * Request parameters for getBreakingLifecycleTransition1 operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiGetBreakingLifecycleTransition1Request
 */
export interface ProjectResourceV2ApiGetBreakingLifecycleTransition1Request {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiGetBreakingLifecycleTransition1
   */
  readonly projectId: number

  /**
   *
   * @type {'EMERGENCE' | 'CREATION' | 'REPRISE' | 'DEVELOPMENT' | 'REBOUND' | 'SCALE'}
   * @memberof ProjectResourceV2ApiGetBreakingLifecycleTransition1
   */
  readonly newLifeCycle: "EMERGENCE" | "CREATION" | "REPRISE" | "DEVELOPMENT" | "REBOUND" | "SCALE"
}

/**
 * Request parameters for getOperations operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiGetOperationsRequest
 */
export interface ProjectResourceV2ApiGetOperationsRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiGetOperations
   */
  readonly projectId: number
}

/**
 * Request parameters for getProductsByAssociation operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiGetProductsByAssociationRequest
 */
export interface ProjectResourceV2ApiGetProductsByAssociationRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiGetProductsByAssociation
   */
  readonly projectId: number

  /**
   * corresponds to associationId
   * @type {string}
   * @memberof ProjectResourceV2ApiGetProductsByAssociation
   */
  readonly xRequestResourceForAtIdX: string
}

/**
 * Request parameters for getProject operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiGetProjectRequest
 */
export interface ProjectResourceV2ApiGetProjectRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiGetProject
   */
  readonly projectId: number

  /**
   * corresponds to associationId
   * @type {string}
   * @memberof ProjectResourceV2ApiGetProject
   */
  readonly xRequestResourceForAtIdX: string
}

/**
 * Request parameters for getRecentlyViewedProjectsByAdvisorId operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiGetRecentlyViewedProjectsByAdvisorIdRequest
 */
export interface ProjectResourceV2ApiGetRecentlyViewedProjectsByAdvisorIdRequest {
  /**
   * advisor id
   * @type {string}
   * @memberof ProjectResourceV2ApiGetRecentlyViewedProjectsByAdvisorId
   */
  readonly advisorId?: string
}

/**
 * Request parameters for importFinancialPlanResources1 operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiImportFinancialPlanResources1Request
 */
export interface ProjectResourceV2ApiImportFinancialPlanResources1Request {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiImportFinancialPlanResources1
   */
  readonly projectId: number
}

/**
 * Request parameters for importOperations1 operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiImportOperations1Request
 */
export interface ProjectResourceV2ApiImportOperations1Request {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiImportOperations1
   */
  readonly projectId: number
}

/**
 * Request parameters for openNoteOnProject operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiOpenNoteOnProjectRequest
 */
export interface ProjectResourceV2ApiOpenNoteOnProjectRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiOpenNoteOnProject
   */
  readonly projectId: number

  /**
   * corresponds to associationId
   * @type {string}
   * @memberof ProjectResourceV2ApiOpenNoteOnProject
   */
  readonly xRequestResourceForAtIdX: string
}

/**
 * Request parameters for retrieveNoteUrlOnProject operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiRetrieveNoteUrlOnProjectRequest
 */
export interface ProjectResourceV2ApiRetrieveNoteUrlOnProjectRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiRetrieveNoteUrlOnProject
   */
  readonly projectId: number
}

/**
 * Request parameters for updateBankLoan operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiUpdateBankLoanRequest
 */
export interface ProjectResourceV2ApiUpdateBankLoanRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiUpdateBankLoan
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof ProjectResourceV2ApiUpdateBankLoan
   */
  readonly bankLoanId: string

  /**
   *
   * @type {UpdateBankLoanRequest}
   * @memberof ProjectResourceV2ApiUpdateBankLoan
   */
  readonly updateBankLoanRequest: UpdateBankLoanRequest
}

/**
 * Request parameters for updateBonus operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiUpdateBonusRequest
 */
export interface ProjectResourceV2ApiUpdateBonusRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiUpdateBonus
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof ProjectResourceV2ApiUpdateBonus
   */
  readonly bonusId: string

  /**
   *
   * @type {UpdateBonusRequest}
   * @memberof ProjectResourceV2ApiUpdateBonus
   */
  readonly updateBonusRequest: UpdateBonusRequest
}

/**
 * Request parameters for updateGuarantee operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiUpdateGuaranteeRequest
 */
export interface ProjectResourceV2ApiUpdateGuaranteeRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiUpdateGuarantee
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof ProjectResourceV2ApiUpdateGuarantee
   */
  readonly guaranteeId: string

  /**
   *
   * @type {UpdateGuaranteeRequest}
   * @memberof ProjectResourceV2ApiUpdateGuarantee
   */
  readonly updateGuaranteeRequest: UpdateGuaranteeRequest
}

/**
 * Request parameters for updateLoan operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiUpdateLoanRequest
 */
export interface ProjectResourceV2ApiUpdateLoanRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiUpdateLoan
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof ProjectResourceV2ApiUpdateLoan
   */
  readonly loanId: string

  /**
   *
   * @type {UpdateLoanRequest}
   * @memberof ProjectResourceV2ApiUpdateLoan
   */
  readonly updateLoanRequest: UpdateLoanRequest
}

/**
 * Request parameters for updateProject operation in ProjectResourceV2Api.
 * @export
 * @interface ProjectResourceV2ApiUpdateProjectRequest
 */
export interface ProjectResourceV2ApiUpdateProjectRequest {
  /**
   *
   * @type {number}
   * @memberof ProjectResourceV2ApiUpdateProject
   */
  readonly projectId: number

  /**
   *
   * @type {UpdateProjectRequest}
   * @memberof ProjectResourceV2ApiUpdateProject
   */
  readonly updateProjectRequest: UpdateProjectRequest
}

/**
 * ProjectResourceV2Api - object-oriented interface
 * @export
 * @class ProjectResourceV2Api
 * @extends {BaseAPI}
 */
export class ProjectResourceV2Api extends BaseAPI {
  /**
   *
   * @summary Add bank loan
   * @param {ProjectResourceV2ApiAddBankLoanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public addBankLoan(
    requestParameters: ProjectResourceV2ApiAddBankLoanRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .addBankLoan(requestParameters.projectId, requestParameters.addBankLoanRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Add bonus
   * @param {ProjectResourceV2ApiAddBonusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public addBonus(
    requestParameters: ProjectResourceV2ApiAddBonusRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .addBonus(requestParameters.projectId, requestParameters.addBonusRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Add guarantee
   * @param {ProjectResourceV2ApiAddGuaranteeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public addGuarantee(
    requestParameters: ProjectResourceV2ApiAddGuaranteeRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .addGuarantee(requestParameters.projectId, requestParameters.addGuaranteeRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Add investment
   * @param {ProjectResourceV2ApiAddLoanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public addLoan(
    requestParameters: ProjectResourceV2ApiAddLoanRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .addLoan(requestParameters.projectId, requestParameters.addLoanRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get note\'s terms of service
   * @param {ProjectResourceV2ApiCheckTermsOfUseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public checkTermsOfUse(
    requestParameters: ProjectResourceV2ApiCheckTermsOfUseRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .checkTermsOfUse(
        requestParameters.projectId,
        requestParameters.checkTermsOfUseRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create a note on project
   * @param {ProjectResourceV2ApiCreateNoteOnProject1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public createNoteOnProject1(
    requestParameters: ProjectResourceV2ApiCreateNoteOnProject1Request,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .createNoteOnProject1(requestParameters.projectId, requestParameters.newLifeCycle, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete bank loan
   * @param {ProjectResourceV2ApiDeleteBankLoanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public deleteBankLoan(
    requestParameters: ProjectResourceV2ApiDeleteBankLoanRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .deleteBankLoan(requestParameters.projectId, requestParameters.bankLoanId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete bonus
   * @param {ProjectResourceV2ApiDeleteBonusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public deleteBonus(
    requestParameters: ProjectResourceV2ApiDeleteBonusRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .deleteBonus(requestParameters.projectId, requestParameters.bonusId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete guarantee
   * @param {ProjectResourceV2ApiDeleteGuaranteeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public deleteGuarantee(
    requestParameters: ProjectResourceV2ApiDeleteGuaranteeRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .deleteGuarantee(requestParameters.projectId, requestParameters.guaranteeId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete investment
   * @param {ProjectResourceV2ApiDeleteInvestmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public deleteInvestment(
    requestParameters: ProjectResourceV2ApiDeleteInvestmentRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .deleteInvestment(requestParameters.projectId, requestParameters.investmentId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Look for projects
   * @param {ProjectResourceV2ApiFindProjectsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public findProjects(
    requestParameters: ProjectResourceV2ApiFindProjectsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .findProjects(
        requestParameters.searchString,
        requestParameters.searchFields,
        requestParameters.filterOnLoggedAdvisor,
        requestParameters.filterOnCommitteeDate,
        requestParameters.filterOnProjectPhase,
        requestParameters.filterOnProjectActivityArea,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Check if lifecycle transition is authorized for project
   * @param {ProjectResourceV2ApiGetBreakingLifecycleTransition1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public getBreakingLifecycleTransition1(
    requestParameters: ProjectResourceV2ApiGetBreakingLifecycleTransition1Request,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .getBreakingLifecycleTransition1(
        requestParameters.projectId,
        requestParameters.newLifeCycle,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get operations by projectId
   * @param {ProjectResourceV2ApiGetOperationsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public getOperations(
    requestParameters: ProjectResourceV2ApiGetOperationsRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .getOperations(requestParameters.projectId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get products by projectId and associationId
   * @param {ProjectResourceV2ApiGetProductsByAssociationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public getProductsByAssociation(
    requestParameters: ProjectResourceV2ApiGetProductsByAssociationRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .getProductsByAssociation(
        requestParameters.projectId,
        requestParameters.xRequestResourceForAtIdX,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get project by its nouba id and association id
   * @param {ProjectResourceV2ApiGetProjectRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public getProject(
    requestParameters: ProjectResourceV2ApiGetProjectRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .getProject(requestParameters.projectId, requestParameters.xRequestResourceForAtIdX, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get recently viewed projects by advisor id
   * @param {ProjectResourceV2ApiGetRecentlyViewedProjectsByAdvisorIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public getRecentlyViewedProjectsByAdvisorId(
    requestParameters: ProjectResourceV2ApiGetRecentlyViewedProjectsByAdvisorIdRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .getRecentlyViewedProjectsByAdvisorId(requestParameters.advisorId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   * Used by nouba to import financial plan resources
   * @summary Get financial plan resources import by projectId
   * @param {ProjectResourceV2ApiImportFinancialPlanResources1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public importFinancialPlanResources1(
    requestParameters: ProjectResourceV2ApiImportFinancialPlanResources1Request,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .importFinancialPlanResources1(requestParameters.projectId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   * Used by nouba to import operations
   * @summary Get operation import by projectId
   * @param {ProjectResourceV2ApiImportOperations1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public importOperations1(
    requestParameters: ProjectResourceV2ApiImportOperations1Request,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .importOperations1(requestParameters.projectId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get opened noteId for project
   * @param {ProjectResourceV2ApiOpenNoteOnProjectRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public openNoteOnProject(
    requestParameters: ProjectResourceV2ApiOpenNoteOnProjectRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .openNoteOnProject(
        requestParameters.projectId,
        requestParameters.xRequestResourceForAtIdX,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get note url for project
   * @param {ProjectResourceV2ApiRetrieveNoteUrlOnProjectRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public retrieveNoteUrlOnProject(
    requestParameters: ProjectResourceV2ApiRetrieveNoteUrlOnProjectRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .retrieveNoteUrlOnProject(requestParameters.projectId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update bank loan
   * @param {ProjectResourceV2ApiUpdateBankLoanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public updateBankLoan(
    requestParameters: ProjectResourceV2ApiUpdateBankLoanRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .updateBankLoan(
        requestParameters.projectId,
        requestParameters.bankLoanId,
        requestParameters.updateBankLoanRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update bonus
   * @param {ProjectResourceV2ApiUpdateBonusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public updateBonus(
    requestParameters: ProjectResourceV2ApiUpdateBonusRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .updateBonus(
        requestParameters.projectId,
        requestParameters.bonusId,
        requestParameters.updateBonusRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update guarantee
   * @param {ProjectResourceV2ApiUpdateGuaranteeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public updateGuarantee(
    requestParameters: ProjectResourceV2ApiUpdateGuaranteeRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .updateGuarantee(
        requestParameters.projectId,
        requestParameters.guaranteeId,
        requestParameters.updateGuaranteeRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update investment
   * @param {ProjectResourceV2ApiUpdateLoanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public updateLoan(
    requestParameters: ProjectResourceV2ApiUpdateLoanRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .updateLoan(
        requestParameters.projectId,
        requestParameters.loanId,
        requestParameters.updateLoanRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update nouba project
   * @param {ProjectResourceV2ApiUpdateProjectRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceV2Api
   */
  public updateProject(
    requestParameters: ProjectResourceV2ApiUpdateProjectRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceV2ApiFp(this.configuration)
      .updateProject(requestParameters.projectId, requestParameters.updateProjectRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}
