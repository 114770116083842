import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react"
import { InputNumber, Space } from "antd"
import { handleUserKeyPress } from "../../utils/keyboard-navigation"
import "./NumberInputWithSteps.scss"
import { MinusOutlined, PlusOutlined } from "@ant-design/icons"
import { Suffixes } from "../../utils/suffixes"
import { thousandsGroupRegex } from "../../utils/formatNumber/FormatNumber"
import { useDebouncedCallback } from "use-debounce"
import { INPUT_DEBOUNCE_DEFAULT_DELAY } from "../../utils/DebounceUtils"

const computeSuffix = (
  value: number,
  singularSuffix?: Suffixes,
  pluralSuffix?: Suffixes,
  inKiloEuros?: boolean
) => {
  const usePlural = pluralSuffix && Math.abs(value) > 1
  const kiloEurosSymbol = inKiloEuros ? "K" : ""

  if (usePlural && pluralSuffix) {
    return kiloEurosSymbol.concat(pluralSuffix)
  } else if (singularSuffix) {
    return kiloEurosSymbol.concat(singularSuffix)
  } else {
    return ""
  }
}

export const NumberInputWithSteps: FC<{
  value: number
  inKiloEuros?: boolean
  suffix?: Suffixes
  pluralSuffix?: Suffixes
  onChange: (value: number | undefined) => void
  disabled?: boolean
  defaultValue?: number
  step?: number
  onlyInteger?: boolean
}> = ({
  value,
  inKiloEuros,
  suffix,
  pluralSuffix,
  onChange,
  disabled,
  defaultValue,
  step = 1,
  onlyInteger = false
}) => {
  const [valueState, setValueState] = useState(value)
  const [displaySuffix, setDisplaySuffix] = useState(
    computeSuffix(value, suffix, pluralSuffix, inKiloEuros)
  )

  useLayoutEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress)
  })

  const refs = useRef({
    isMounted: false
  })

  const debouncedOnChange = useDebouncedCallback(() => {
    if (refs.current.isMounted) {
      if (valueState) {
        onChange(valueState)
      }
    } else {
      refs.current.isMounted = true
    }
  }, INPUT_DEBOUNCE_DEFAULT_DELAY)

  useEffect(() => debouncedOnChange(), [debouncedOnChange, valueState])

  const updateValue = (value: number | null) => {
    if (value || value === 0) {
      setValueState(value)
      setDisplaySuffix(computeSuffix(value, suffix, pluralSuffix, inKiloEuros))
    }
  }

  const getFormatter = (value?: number) => {
    const valueWithReplacedDecimalSeparator = `${value}${
      displaySuffix ? `${displaySuffix}` : ""
    }`.replace(/\./g, ",")

    if (onlyInteger) {
      return valueWithReplacedDecimalSeparator.replace(/,/g, "")
    }

    const [beforeDecimalSeparator, afterDecimalSeparator] =
      valueWithReplacedDecimalSeparator.split(",")

    return `${beforeDecimalSeparator.replace(thousandsGroupRegex, "$1 ")}${
      afterDecimalSeparator ? `,${afterDecimalSeparator}` : ""
    }`
  }

  const getParser = (value?: string) =>
    Number(
      value!
        .replace(`${displaySuffix ? displaySuffix : ""}`, "")
        .replace(/,/g, ".")
        .replace(/\s/g, "")
    )

  return (
    <Space.Compact>
      <InputNumber
        changeOnWheel={false}
        onDoubleClick={mouseEvent => mouseEvent.currentTarget.select()}
        value={valueState}
        defaultValue={defaultValue}
        min={0}
        step={step}
        formatter={getFormatter}
        parser={getParser}
        controls={{ upIcon: <PlusOutlined />, downIcon: <MinusOutlined /> }}
        decimalSeparator={","}
        precision={2}
        onChange={(value: number | null) => updateValue(value)}
        disabled={disabled}
        status={undefined} // TODO use intent
      />
    </Space.Compact>
  )
}
