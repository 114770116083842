import React, { FC, useContext, useEffect } from "react"
import { NoteContainer } from "./NoteContainer"
import { ProjectContext } from "./ProjectWrapper"
import Loader from "../components/Loader/Loader"
import { useGetNoteSettings } from "../queries/NoteSettingsQueries"
import { HolderRequest, Note, NoteSettings, NoubaHolder } from "../client/backend-client/generated"
import { Layout } from "antd"
import { SiderMenu } from "../components/SiderMenu/SiderMenu"
import { useGetNote } from "../queries/NoteQueries"
import { CreationExNihiloDialog } from "./presentation/overview/CreationExNihiloDialog"
import { useGetHolders, useGetNoteNoubaHolders } from "../queries/HolderQueries"

const { Content } = Layout

interface NoteContextI {
  note: Note
  baseNotePath: string
  projectName: string
  noteId: string
  noteSettings: NoteSettings
  areNoubaHoldersLoading: boolean
  noubaHolders: NoubaHolder[]
  areHoldersLoading: boolean
  holders: HolderRequest[]
}

export const NoteContext = React.createContext<NoteContextI>({} as NoteContextI)

export const NotePage: FC<{ readonly noteId: string }> = ({ noteId }) => {
  const { associationId, projectId, projectName } = useContext(ProjectContext)
  const { isLoading: isNoteLoading, data: note } = useGetNote(noteId)
  const { isLoading: areSettingsLoading, data: noteSettings } = useGetNoteSettings(noteId)
  const { isLoading: areNoubaHoldersLoading, data: noubaHolders } =
    useGetNoteNoubaHolders(projectId)
  const { isLoading: areHoldersLoading, data: holders } = useGetHolders(projectId)

  const loading = isNoteLoading || areSettingsLoading

  const baseNotePath = `/association/${associationId}/project/${projectId}/note`

  const setMetaTitle = () => {
    if (window.location.href.match("\\/project\\/.*")) {
      document.title = projectName
    } else {
      document.title = "France Active - YODA"
    }
  }

  useEffect(() => {
    setMetaTitle()
    return () => {
      setMetaTitle()
    }
  }, [])

  return (
    <>
      {loading && <Loader />}
      {!loading && note && noteSettings && holders && (
        <NoteContext.Provider
          value={{
            note,
            projectName,
            baseNotePath,
            noteSettings: noteSettings,
            noteId: note.noteId,
            areNoubaHoldersLoading: areNoubaHoldersLoading,
            noubaHolders: noubaHolders ?? [],
            holders: holders ?? [],
            areHoldersLoading: areHoldersLoading
          }}
        >
          <Layout hasSider>
            <SiderMenu />
            <Layout style={{ padding: "24px" }}>
              <CreationExNihiloDialog />
              <Content>
                <NoteContainer />
              </Content>
            </Layout>
          </Layout>
        </NoteContext.Provider>
      )}
    </>
  )
}
