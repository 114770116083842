import React, { CSSProperties, FC, useEffect, useState } from "react"
import { AddressUpdateRequest, ApiAddressSearch } from "../../client/backend-client/generated"
import { AutoComplete, AutoCompleteProps, Col, Flex, Row, Space, Typography } from "antd"
import { useSearchAddress } from "../../queries/AddressQueries"
import { useDebouncedCallback } from "use-debounce"
import { SEARCH_DEBOUNCE_DEFAULT_DELAY } from "../../utils/DebounceUtils"
import { EnvironmentOutlined } from "@ant-design/icons"
import { AppMetierSwitch } from "../AppMetierSwitch"
import { DefaultOptionType } from "antd/es/select"

const { Text } = Typography

interface AddressOption extends DefaultOptionType {
  label: React.ReactNode
  value?: string | number | null
  children?: Omit<DefaultOptionType, "children">[]
  address?: ApiAddressSearch
}

export const AddressAutoComplete: FC<{
  onChange: (value: AddressUpdateRequest) => void
  allowClear?: boolean
  disabled?: boolean
  style?: CSSProperties
}> = ({ onChange, allowClear, disabled, style }) => {
  const [searchText, setSearchText] = useState<string>("")
  const [isOverseas, setIsOverseas] = useState(false)
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([])
  const [selectedAddress, setSelectedAddress] = useState<ApiAddressSearch>()

  const { isLoading: areAddressesLoading, data: addresses } = useSearchAddress(
    searchText,
    isOverseas
  )

  const debouncedOnSearchTextChange = useDebouncedCallback((value?: string) => {
    setSearchText(value && value.trim() ? value : "")
  }, SEARCH_DEBOUNCE_DEFAULT_DELAY)

  useEffect(() => {
    if (!areAddressesLoading && addresses) {
      setOptions(addresses.map(renderAddressOption))
    }
  }, [addresses])

  const onSelect = (addressOption: AddressOption) => {
    setSelectedAddress(addressOption.address)
  }

  useEffect(() => {
    if (selectedAddress) {
      onChange({
        adresse1: selectedAddress.adresse1,
        codePostal: selectedAddress.codePostal,
        ville: selectedAddress.ville,
        codeCommune: selectedAddress.codeCommune,
        departementCode: selectedAddress.departementCode,
        code: selectedAddress.code
      })
    }
  }, [selectedAddress])

  const renderAddressOption = (address: ApiAddressSearch): AddressOption => ({
    value: `${address.adresse1}, ${address.codePostal} ${address.ville}`,
    address: address,
    label: (
      <>
        <Flex align="center" justify="start">
          <Col span={1} style={{ textAlign: "center" }}>
            <EnvironmentOutlined />
          </Col>
          <Col span={11}>
            <Space direction="vertical">
              <Text>
                <Text strong>{address.adresse1}</Text>, {address.codePostal + " " + address.ville}
              </Text>
            </Space>
          </Col>
        </Flex>
      </>
    )
  })

  return (
    <Row gutter={[16, 16]} justify={"start"} align={"middle"}>
      <Col span={24}>
        <AutoComplete
          searchValue={searchText}
          options={options}
          onSearch={debouncedOnSearchTextChange}
          onSelect={(_, option: AddressOption) => onSelect(option)}
          style={style ?? { width: "100%" }}
          allowClear={allowClear}
          disabled={disabled}
        />
      </Col>
      <Col span={24}>
        <AppMetierSwitch
          checked={isOverseas}
          label="Rechercher dans un territoir d'Outre-Mer"
          onChange={() => setIsOverseas(!isOverseas)}
          checkedInnerLabel="Oui"
          uncheckedInnerLabel="Non"
        />
      </Col>
    </Row>
  )
}
