import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { Button, Card, Col, Row, Select, Space, Tooltip, Typography } from "antd"
import {
  Advisor,
  NoteAvancementBanqueEnum,
  RegisAssociation,
  SaveNoteRequestAvancementBanqueEnum
} from "../../../client/backend-client/generated"
import "./PresentationStyle.scss"
import "leaflet/dist/leaflet.css"
import { NoteContext } from "../../NotePage"
import { AppMetierSelect } from "../../../components/AppMetierSelect/AppMetierSelect"
import { AppMetierTitle } from "../../../components/AppMetierTitle/AppMetierTitle"
import {
  AppMetierDateInput,
  SERVER_DATE_FORMAT
} from "../../../components/AppMetierDateInput/AppMetierDateInput"
import dayjs from "dayjs"
import { useUpdateNote } from "../../../queries/NoteQueries"
import { ProjectContext } from "../../ProjectWrapper"
import { formatDayjs } from "../../../utils/DayjsUtils"
import { getLifecycle } from "../../infos/project/ProjectLifecycleSelector"
import { NavLink } from "react-router-dom"
import { EditOutlined } from "@ant-design/icons"
import { getAvancementBanqueLabel } from "../../../utils/EnumToLibelle"

const { Text } = Typography

export const PresentationHeader: FC<{
  association: RegisAssociation
  collaborators: Advisor[]
}> = ({ association, collaborators }) => {
  const { note, noteId, baseNotePath } = useContext(NoteContext)
  const { project } = useContext(ProjectContext)

  const updateNoteMutation = useUpdateNote(noteId)

  const [committeeDate, setCommitteeDate] = useState(
    note.projectCommitteeDate ? dayjs(note.projectCommitteeDate) : undefined
  )
  const [avancementBanque, setAvancementBanque] = useState(note.avancementBanque)

  const refs = useRef({
    isMounted: false
  })

  const onChangeAdvisor = (advisor: Advisor): void => {
    updateNoteMutation.mutate({
      noteId,
      advisor: advisor
    })
  }

  useEffect(() => {
    if (refs.current.isMounted) {
      const noteProjectCommitteeDate = note.projectCommitteeDate
        ? dayjs(note.projectCommitteeDate)
        : undefined
      const areCommitteeDatesEqual =
        !!noteProjectCommitteeDate && !!committeeDate
          ? noteProjectCommitteeDate.isSame(committeeDate)
          : true

      if (
        noteProjectCommitteeDate !== undefined ||
        committeeDate !== undefined ||
        !areCommitteeDatesEqual ||
        avancementBanque !== note.avancementBanque
      ) {
        updateNoteMutation.mutate({
          noteId,
          avancementBanque: avancementBanque
            ? SaveNoteRequestAvancementBanqueEnum[avancementBanque]
            : undefined,
          projectCommitteeDate: committeeDate
            ? formatDayjs(committeeDate, SERVER_DATE_FORMAT)
            : undefined
        })
      }
    } else {
      refs.current.isMounted = true
    }
  }, [avancementBanque, committeeDate])

  return (
    <Card className="presentation presentation-header">
      <Row>
        <Col span={24} xl={16}>
          <Space>
            <AppMetierTitle level={5}>Projet</AppMetierTitle>
            <NavLink to={`${baseNotePath}/infos/project`}>
              <Tooltip title="Éditer le projet">
                <Button size="small" shape="circle" icon={<EditOutlined />} />
              </Tooltip>
            </NavLink>
          </Space>
          <Row align="middle" className="grey-spans inner-header inner-header-projet">
            <Col span={4}>
              <>
                <Text>Phase de vie</Text>
              </>
            </Col>
            <Col span={8}>
              <Text strong>{getLifecycle(note.lifecycle)}</Text>
            </Col>
            <Col span={4}>
              <Text>Avancement banque</Text>
            </Col>
            <Col span={8}>
              <AppMetierSelect<NoteAvancementBanqueEnum>
                value={avancementBanque}
                options={Object.values(NoteAvancementBanqueEnum)}
                valueToSelectItem={value => ({
                  value: value,
                  label: getAvancementBanqueLabel(value)
                })}
                onChange={setAvancementBanque}
              />
            </Col>
            <Col span={4}>
              <Text>Emplois créés</Text>
            </Col>
            <Col span={8}>
              <Text strong>{project.createdJobs}</Text>
            </Col>
            <Col span={4}>
              <Text>Date comité</Text>
            </Col>
            <Col span={8}>
              <AppMetierDateInput
                value={committeeDate}
                onChange={setCommitteeDate}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={4}>
              <Text>Territoire fragile</Text>
            </Col>
            <Col span={8}>
              <Text>{project.fragileArea?.libelle ?? ""}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={24} xl={8}>
          <AppMetierTitle level={5}>Suivi</AppMetierTitle>
          <Row align="middle" className="inner-header inner-header-suivi">
            <Col span={10}>
              <Text style={{ color: "white" }}>Conseiller</Text>
            </Col>
            <Col span={14}>
              {collaborators && collaborators.length > 0 && (
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  value={collaborators.find(c => c.regisId === note.advisor?.regisId)?.email}
                  placeholder=""
                  optionFilterProp="children"
                  onChange={e => {
                    const result = collaborators.find(c => c.email === e)
                    if (result) {
                      onChangeAdvisor(result)
                    }
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  options={collaborators?.map(e => {
                    return {
                      value: e.email,
                      label: `${e.firstName} ${e.lastName}`
                    }
                  })}
                />
              )}
            </Col>
            <Col span={10}>
              <Text style={{ color: "white" }}>Asso. territoriale</Text>
            </Col>
            <Col span={14}>
              <Text strong style={{ color: "white" }}>
                {association.name ?? "-"}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
