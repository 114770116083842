import React, { CSSProperties, FC } from "react"
import { SelectItem } from "./AppMetierSelect"
import { RegisAssociation } from "../../client/backend-client/generated"
import { Select } from "antd"
import { sortAssociationByName, useGetAssociations } from "../../queries/AssociationQueries"

export const associationToSelectItem: (
  association: RegisAssociation
) => SelectItem<string> = value => ({
  value: value.associationCode,
  label: value.name
})

export const AssociationSelect: FC<{
  associationCode?: string
  onChange: (value: string) => void
  allowClear?: boolean
  disabled?: boolean
  style?: CSSProperties
}> = ({ associationCode, onChange, allowClear, disabled, style }) => {
  const { isLoading: areAssociationsLoading, data: associations } = useGetAssociations()

  const initialAssociation = associations?.find(
    association => association.associationCode === associationCode
  )

  return (
    <>
      {associations && (
        <Select<SelectItem<string>>
          value={initialAssociation && associationToSelectItem(initialAssociation)}
          labelInValue
          style={style ?? { width: "100%" }}
          onChange={(selectedAssociation: SelectItem<string>) =>
            onChange(selectedAssociation?.value)
          }
          allowClear={allowClear}
          loading={areAssociationsLoading}
          disabled={disabled}
          options={associations.sort(sortAssociationByName).map(associationToSelectItem)}
        />
      )}
    </>
  )
}
