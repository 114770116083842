import React, { FC, useContext } from "react"
import { ProjectContext } from "../../ProjectWrapper"
import { ProjectForm } from "./ProjectForm"
import { DEFAULT_QUERY_RESULT } from "../../../utils/reactQueryUtils"
import Loader from "../../../components/Loader/Loader"
import { useGetAssociation } from "../../../queries/AssociationQueries"

export const ProjectPageWrapper: FC = () => {
  const { project } = useContext(ProjectContext)
  const { data: creatorAssociation, isLoading: isCreatorAssociationLoading } =
    project.projectLeadCreatorCode
      ? useGetAssociation(project.projectLeadCreatorCode)
      : DEFAULT_QUERY_RESULT

  if (isCreatorAssociationLoading) {
    return <Loader />
  }

  return <>{creatorAssociation && <ProjectForm creatorAssociation={creatorAssociation} />}</>
}
