/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CodeLibelle } from "./code-libelle"

/**
 *
 * @export
 * @interface HolderRequest
 */
export interface HolderRequest {
  /**
   *
   * @type {string}
   * @memberof HolderRequest
   */
  roleId?: string
  /**
   *
   * @type {string}
   * @memberof HolderRequest
   */
  dateEntreeAccompagnement?: string
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  majeurInscritMissionLocale?: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  inscritCapEmploi?: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  sortantAse?: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  parentIsole?: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  personneSansDomicileHebergee?: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  personneRecemmentArriveeFrance?: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  accompagnementPostCreation?: boolean
  /**
   *
   * @type {string}
   * @memberof HolderRequest
   */
  precisionNiveauQuatre?: HolderRequestPrecisionNiveauQuatreEnum
  /**
   *
   * @type {CodeLibelle}
   * @memberof HolderRequest
   */
  prescriptionSortie?: CodeLibelle
  /**
   *
   * @type {CodeLibelle}
   * @memberof HolderRequest
   */
  motifSortie?: CodeLibelle
  /**
   *
   * @type {string}
   * @memberof HolderRequest
   */
  dateSortie?: string
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  parcoursAccesPlus: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum HolderRequestPrecisionNiveauQuatreEnum {
  SORTIE = "SORTIE",
  JEUNE_DIPLOME = "JEUNE_DIPLOME",
  DIFFICULTE_PERSO = "DIFFICULTE_PERSO",
  PRECARITE_ECONOMIQUE = "PRECARITE_ECONOMIQUE",
  MANQUE_CRITERE = "MANQUE_CRITERE",
  AUTRE = "AUTRE"
}
