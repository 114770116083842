import React, { FC, lazy, Suspense, useContext } from "react"
import { Route, Routes } from "react-router-dom"
import Loader from "../components/Loader/Loader"
import { InvolvementCheckPageWrapper } from "./involvement-check/InvolvementCheckPageWrapper"
import { MementoWrapper } from "./memento/MementoWrapper"
import { HistoryFormWrapper } from "./history/HistoryFormWrapper"
import { NoteContext } from "./NotePage"
import { useGetPeriods } from "./financial/settings/period/PeriodQueries"
import { PeriodsContext } from "./financial/PeriodsContext"
import { Presentation } from "./presentation/overview/Presentation"

const InfosPageWrapper = lazy(() => import("./infos/InfosPageWrapper"))

const FinancialPageWrapper = lazy(() => import("./financial/FinancialPageWrapper"))

const AnalysisPageWrapper = lazy(() => import("./analysis/AnalysisPageWrapper"))

export const NoteContainer: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { isLoading: periodLoading, data: allPeriods } = useGetPeriods(noteId)

  return (
    <>
      {periodLoading && <Loader />}
      {allPeriods && (
        <PeriodsContext.Provider
          value={{
            previousPeriods: allPeriods.previous,
            forecastPeriods: allPeriods.forecast,
            intermediaryPeriod: allPeriods.intermediary
          }}
        >
          <Routes>
            <Route
              path={`/presentation`}
              element={
                <Suspense fallback={<Loader />}>
                  <Presentation />
                </Suspense>
              }
            />
            <Route
              path={`/infos/*`}
              element={
                <Suspense fallback={<Loader />}>
                  <InfosPageWrapper />
                </Suspense>
              }
            />
            <Route
              path={`/involvement-check`}
              element={
                <Suspense fallback={<Loader />}>
                  <InvolvementCheckPageWrapper />
                </Suspense>
              }
            />
            <Route
              path={`/analysis/*`}
              element={
                <Suspense fallback={<Loader />}>
                  <AnalysisPageWrapper />
                </Suspense>
              }
            />
            <Route
              path={`/financial/*`}
              element={
                <Suspense fallback={<Loader />}>
                  <FinancialPageWrapper />
                </Suspense>
              }
            />
            <Route
              path={`/memento`}
              element={
                <Suspense fallback={<Loader />}>
                  <MementoWrapper />
                </Suspense>
              }
            />
            <Route
              path={`/history`}
              element={
                <Suspense fallback={<Loader />}>
                  <HistoryFormWrapper />
                </Suspense>
              }
            />
          </Routes>
        </PeriodsContext.Provider>
      )}
    </>
  )
}
