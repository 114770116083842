import React, { FC, useContext } from "react"
import Loader from "../../../components/Loader/Loader"
import { NoteContext } from "../../NotePage"
import { Alert, Tabs } from "antd"
import { HolderForm } from "./HolderForm"
import { HolderRequest } from "../../../client/backend-client/generated"

export const HolderPageWrapper: FC = () => {
  const { areNoubaHoldersLoading, noubaHolders, areHoldersLoading, holders } =
    useContext(NoteContext)

  if (areNoubaHoldersLoading || areHoldersLoading || !noubaHolders || !holders) {
    return <Loader />
  }

  return (
    <>
      {noubaHolders &&
        (noubaHolders.length > 0 ? (
          <Tabs
            items={noubaHolders.map(noubaHolder => {
              const holder = holders.find(holder => holder.roleId === noubaHolder.id)
              return {
                label: `${noubaHolder.preferredName || noubaHolder.lastName || ""} ${noubaHolder.firstName || ""}`,
                key: noubaHolder.id ?? "",
                children: noubaHolder.id && (
                  <HolderForm
                    holderId={noubaHolder.id}
                    noubaHolder={noubaHolder}
                    holder={holder ?? ({} as HolderRequest)}
                  />
                )
              }
            })}
          />
        ) : (
          <Alert message="Aucun porteur n'a été trouvé sur cette note." type="warning" />
        ))}
    </>
  )
}
