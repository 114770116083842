import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { Card, Descriptions, Divider, Space, Typography } from "antd"
import { ProjectContext } from "../../ProjectWrapper"
import {
  CodeLibelle,
  ExpertiseProjetRecap,
  Project,
  RegisAssociation,
  UpdateProjectRequest
} from "../../../client/backend-client/generated"
import { NoteContext } from "../../NotePage"
import { ProjectLifecycleSelector } from "./ProjectLifecycleSelector"
import { ReferenceSelect } from "../../../components/AppMetierSelect/ReferenceSelect"
import { ReferenceCode } from "../../../queries/ReferenceQueries"
import { AppMetierSwitch } from "../../../components/AppMetierSwitch"
import { AppMetierTextInput } from "../../../components/AppMetierTextInput/AppMetierTextInput"
import TextArea from "antd/es/input/TextArea"
import { AssociationSelect } from "../../../components/AppMetierSelect/AssociationSelect"
import { toProjectRequest, useUpdateProject } from "../../../queries/ProjectQueries"
import _ from "lodash"
import { useDebouncedCallback } from "use-debounce"
import {
  getFlushEffectCallback,
  LONG_UPDATE_DEBOUNCE_DEFAULT_DELAY
} from "../../../utils/DebounceUtils"
import { EtpDetailTable } from "./EtpTable"

const { Text } = Typography

const LABEL_STYLE = { width: "30%" }

export const ProjectForm: FC<{
  creatorAssociation: RegisAssociation
}> = ({ creatorAssociation }) => {
  const { project, projectId } = useContext(ProjectContext)
  const { note } = useContext(NoteContext)

  const updateProjectMutation = useUpdateProject(projectId)

  const [prescriberType, setPrescriberType] = useState<CodeLibelle | undefined>(
    project.prescriber?.typePrescriber
  )
  const [companionType, setCompanionType] = useState<CodeLibelle | undefined>(
    project.companion?.typeCompanion
  )

  const projectToRequest = (project: Project): UpdateProjectRequest => ({
    label: project.label,
    shortDescription: project.shortDescription,
    projectLeadCreatorCode: creatorAssociation.associationCode,
    projectLeadDelegatedCode: project.projectLeadDelegatedCode,
    fundingObject: project.fundingObject,
    prescriber: project.prescriber?.codeLibelle,
    companion: project.companion?.codeLibelle,
    isComplex: project.isComplex,
    complexComment: project.complexComment,
    donneesEmploisProjet: project.donneesEmploisProjet
  })

  const refs = useRef({
    initialProjectRequest: projectToRequest(project)
  })

  const [projectRequest, setProjectRequest] = useState<UpdateProjectRequest>(
    refs.current.initialProjectRequest
  )

  const debouncedOnProjectRequestChange = useDebouncedCallback(() => {
    if (isProjectRequestValid()) {
      updateProjectMutation.mutate(
        toProjectRequest(refs.current.initialProjectRequest, projectRequest),
        {
          onSuccess: res => {
            refs.current.initialProjectRequest = projectToRequest(res)
          }
        }
      )
    }
  }, LONG_UPDATE_DEBOUNCE_DEFAULT_DELAY)

  useEffect(debouncedOnProjectRequestChange, [projectRequest])
  useEffect(getFlushEffectCallback(debouncedOnProjectRequestChange), [
    debouncedOnProjectRequestChange
  ])

  const isProjectRequestValid = () => {
    return (
      !_.isEqual(projectRequest, {}) &&
      !_.isEqual(refs.current.initialProjectRequest, projectRequest) &&
      !!projectRequest.prescriber &&
      projectRequest.label &&
      (!projectRequest.isComplex || projectRequest.complexComment)
    )
  }

  return (
    <Card>
      <Space direction="vertical" className="full-width-space">
        <Descriptions bordered size="middle" column={1}>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Libellé*</Text>}>
            <AppMetierTextInput
              maxLength={50}
              inputValue={projectRequest.label}
              onChange={value => {
                setProjectRequest({ ...projectRequest, label: value })
              }}
              status={projectRequest.label ? undefined : "error"}
              name=""
            />
            {!projectRequest.label && <RequiredFieldMessage />}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={LABEL_STYLE}
            label={<Text strong>Description courte*</Text>}
          >
            <TextArea
              value={projectRequest.shortDescription}
              onChange={value => {
                setProjectRequest({ ...projectRequest, shortDescription: value.target.value })
              }}
              autoSize={{ minRows: 2, maxRows: 6 }}
              status={projectRequest.shortDescription ? undefined : "error"}
            />
            {!projectRequest.shortDescription && <RequiredFieldMessage />}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>AT en charge</Text>}>
            {creatorAssociation?.name}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>AT délégataire</Text>}>
            <AssociationSelect
              associationCode={projectRequest.projectLeadDelegatedCode}
              allowClear={true}
              onChange={value =>
                setProjectRequest({ ...projectRequest, projectLeadDelegatedCode: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Phase de vie*</Text>}>
            <ProjectLifecycleSelector
              noteId={note.noteId}
              lifecycle={note.lifecycle}
              creationExNihilo={note.creationExNihilo}
            />
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={LABEL_STYLE}
            label={<Text strong>Objet du financement*</Text>}
          >
            <ReferenceSelect
              referenceCode={ReferenceCode.OBJET_FINANCEMENT}
              disabled={true}
              codeLibelle={projectRequest.fundingObject}
            />
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={LABEL_STYLE}
            label={<Text strong>Type de Prescripteur*</Text>}
          >
            <ReferenceSelect
              referenceCode={ReferenceCode.TYPE_RECOMMANDEUR}
              codeLibelle={prescriberType}
              onChange={value => {
                setPrescriberType(value)
                if (projectRequest.prescriber?.typeRecommandeurCode !== value?.code) {
                  setProjectRequest({ ...projectRequest, prescriber: undefined })
                }
              }}
            />
            {!prescriberType && <RequiredFieldMessage />}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Prescripteur*</Text>}>
            <ReferenceSelect
              status={projectRequest.prescriber ? undefined : "error"}
              referenceCode={ReferenceCode.RECOMMANDEUR}
              codeLibelle={projectRequest.prescriber}
              prescriberType={prescriberType}
              disabled={!prescriberType}
              onChange={(value?: CodeLibelle) => {
                setProjectRequest({ ...projectRequest, prescriber: value })
              }}
            />
            {!projectRequest.prescriber && <RequiredFieldMessage />}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={LABEL_STYLE}
            label={<Text strong>Type d'accompagnateur</Text>}
          >
            <ReferenceSelect
              referenceCode={ReferenceCode.TYPE_RECOMMANDEUR}
              codeLibelle={companionType}
              onChange={value => {
                setCompanionType(value)
                if (projectRequest.companion?.typeRecommandeurCode !== value?.code) {
                  setProjectRequest({ ...projectRequest, companion: undefined })
                }
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Accompagnateur</Text>}>
            <ReferenceSelect
              referenceCode={ReferenceCode.RECOMMANDEUR}
              codeLibelle={projectRequest.companion}
              prescriberType={companionType}
              disabled={!companionType}
              allowClear={true}
              onChange={(value?: CodeLibelle) => {
                setProjectRequest({ ...projectRequest, companion: value })
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Projet complexe</Text>}>
            <AppMetierSwitch
              checked={projectRequest.isComplex ?? false}
              label=""
              onChange={() => {
                setProjectRequest({ ...projectRequest, isComplex: !projectRequest.isComplex })
              }}
              checkedInnerLabel="Oui"
              uncheckedInnerLabel="Non"
            />
          </Descriptions.Item>
          {projectRequest.isComplex && (
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Text strong>Commentaire de projet complexe*</Text>}
            >
              <TextArea
                value={projectRequest.complexComment}
                onChange={value => {
                  setProjectRequest({ ...projectRequest, complexComment: value.target.value })
                }}
                autoSize={{ minRows: 2, maxRows: 6 }}
                status={!projectRequest.complexComment ? "error" : undefined}
              />
              {!projectRequest.complexComment && <RequiredFieldMessage />}
            </Descriptions.Item>
          )}
        </Descriptions>
        <Divider />
        <EtpDetailTable
          employmentData={projectRequest.donneesEmploisProjet}
          onChangeEtp={(value: ExpertiseProjetRecap) => {
            setProjectRequest({ ...projectRequest, donneesEmploisProjet: value })
          }}
        ></EtpDetailTable>
      </Space>
    </Card>
  )
}

export const RequiredFieldMessage = () => <Text type="danger">Le champ est obligatoire</Text>
