/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { CodeLibelle } from "../model"
/**
 * ReferenceResourceApi - axios parameter creator
 * @export
 */
export const ReferenceResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get a reference by its code
     * @param {'STATUT_JURIDIQUE' | 'SECTEUR_ACTIVITE' | 'STATUT_COOPERATIF' | 'AGREMENT_ESUS' | 'RECOMMANDEUR' | 'TYPE_RECOMMANDEUR' | 'PHASE_PROJET' | 'TERRITOIRE_FRAGILE' | 'OBJET_FINANCEMENT' | 'OBJET_PROJET' | 'FINALITE_SOCIETALE' | 'CODE_APE' | 'TYPE_ROLE' | 'CIVILITE' | 'SITUATION_MATRIMONIALE' | 'SITUATION_PROFESSIONNELLE' | 'TYPE_REVENU' | 'NIVEAU_ETUDE' | 'BENEFICIAIRE_MINIMA_SOCIAUX' | 'ZONAGE_EPCI' | 'MOTIF_ABANDON_ACCES_PLUS' | 'PRESCRIPTION_CAS_SORTIE'} referenceCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReferenceByCode: async (
      referenceCode:
        | "STATUT_JURIDIQUE"
        | "SECTEUR_ACTIVITE"
        | "STATUT_COOPERATIF"
        | "AGREMENT_ESUS"
        | "RECOMMANDEUR"
        | "TYPE_RECOMMANDEUR"
        | "PHASE_PROJET"
        | "TERRITOIRE_FRAGILE"
        | "OBJET_FINANCEMENT"
        | "OBJET_PROJET"
        | "FINALITE_SOCIETALE"
        | "CODE_APE"
        | "TYPE_ROLE"
        | "CIVILITE"
        | "SITUATION_MATRIMONIALE"
        | "SITUATION_PROFESSIONNELLE"
        | "TYPE_REVENU"
        | "NIVEAU_ETUDE"
        | "BENEFICIAIRE_MINIMA_SOCIAUX"
        | "ZONAGE_EPCI"
        | "MOTIF_ABANDON_ACCES_PLUS"
        | "PRESCRIPTION_CAS_SORTIE",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'referenceCode' is not null or undefined
      assertParamExists("getReferenceByCode", "referenceCode", referenceCode)
      const localVarPath = `/reference/{referenceCode}`.replace(
        `{${"referenceCode"}}`,
        encodeURIComponent(String(referenceCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ReferenceResourceApi - functional programming interface
 * @export
 */
export const ReferenceResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReferenceResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get a reference by its code
     * @param {'STATUT_JURIDIQUE' | 'SECTEUR_ACTIVITE' | 'STATUT_COOPERATIF' | 'AGREMENT_ESUS' | 'RECOMMANDEUR' | 'TYPE_RECOMMANDEUR' | 'PHASE_PROJET' | 'TERRITOIRE_FRAGILE' | 'OBJET_FINANCEMENT' | 'OBJET_PROJET' | 'FINALITE_SOCIETALE' | 'CODE_APE' | 'TYPE_ROLE' | 'CIVILITE' | 'SITUATION_MATRIMONIALE' | 'SITUATION_PROFESSIONNELLE' | 'TYPE_REVENU' | 'NIVEAU_ETUDE' | 'BENEFICIAIRE_MINIMA_SOCIAUX' | 'ZONAGE_EPCI' | 'MOTIF_ABANDON_ACCES_PLUS' | 'PRESCRIPTION_CAS_SORTIE'} referenceCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReferenceByCode(
      referenceCode:
        | "STATUT_JURIDIQUE"
        | "SECTEUR_ACTIVITE"
        | "STATUT_COOPERATIF"
        | "AGREMENT_ESUS"
        | "RECOMMANDEUR"
        | "TYPE_RECOMMANDEUR"
        | "PHASE_PROJET"
        | "TERRITOIRE_FRAGILE"
        | "OBJET_FINANCEMENT"
        | "OBJET_PROJET"
        | "FINALITE_SOCIETALE"
        | "CODE_APE"
        | "TYPE_ROLE"
        | "CIVILITE"
        | "SITUATION_MATRIMONIALE"
        | "SITUATION_PROFESSIONNELLE"
        | "TYPE_REVENU"
        | "NIVEAU_ETUDE"
        | "BENEFICIAIRE_MINIMA_SOCIAUX"
        | "ZONAGE_EPCI"
        | "MOTIF_ABANDON_ACCES_PLUS"
        | "PRESCRIPTION_CAS_SORTIE",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeLibelle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReferenceByCode(
        referenceCode,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * ReferenceResourceApi - factory interface
 * @export
 */
export const ReferenceResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ReferenceResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get a reference by its code
     * @param {'STATUT_JURIDIQUE' | 'SECTEUR_ACTIVITE' | 'STATUT_COOPERATIF' | 'AGREMENT_ESUS' | 'RECOMMANDEUR' | 'TYPE_RECOMMANDEUR' | 'PHASE_PROJET' | 'TERRITOIRE_FRAGILE' | 'OBJET_FINANCEMENT' | 'OBJET_PROJET' | 'FINALITE_SOCIETALE' | 'CODE_APE' | 'TYPE_ROLE' | 'CIVILITE' | 'SITUATION_MATRIMONIALE' | 'SITUATION_PROFESSIONNELLE' | 'TYPE_REVENU' | 'NIVEAU_ETUDE' | 'BENEFICIAIRE_MINIMA_SOCIAUX' | 'ZONAGE_EPCI' | 'MOTIF_ABANDON_ACCES_PLUS' | 'PRESCRIPTION_CAS_SORTIE'} referenceCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReferenceByCode(
      referenceCode:
        | "STATUT_JURIDIQUE"
        | "SECTEUR_ACTIVITE"
        | "STATUT_COOPERATIF"
        | "AGREMENT_ESUS"
        | "RECOMMANDEUR"
        | "TYPE_RECOMMANDEUR"
        | "PHASE_PROJET"
        | "TERRITOIRE_FRAGILE"
        | "OBJET_FINANCEMENT"
        | "OBJET_PROJET"
        | "FINALITE_SOCIETALE"
        | "CODE_APE"
        | "TYPE_ROLE"
        | "CIVILITE"
        | "SITUATION_MATRIMONIALE"
        | "SITUATION_PROFESSIONNELLE"
        | "TYPE_REVENU"
        | "NIVEAU_ETUDE"
        | "BENEFICIAIRE_MINIMA_SOCIAUX"
        | "ZONAGE_EPCI"
        | "MOTIF_ABANDON_ACCES_PLUS"
        | "PRESCRIPTION_CAS_SORTIE",
      options?: any
    ): AxiosPromise<Array<CodeLibelle>> {
      return localVarFp
        .getReferenceByCode(referenceCode, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getReferenceByCode operation in ReferenceResourceApi.
 * @export
 * @interface ReferenceResourceApiGetReferenceByCodeRequest
 */
export interface ReferenceResourceApiGetReferenceByCodeRequest {
  /**
   *
   * @type {'STATUT_JURIDIQUE' | 'SECTEUR_ACTIVITE' | 'STATUT_COOPERATIF' | 'AGREMENT_ESUS' | 'RECOMMANDEUR' | 'TYPE_RECOMMANDEUR' | 'PHASE_PROJET' | 'TERRITOIRE_FRAGILE' | 'OBJET_FINANCEMENT' | 'OBJET_PROJET' | 'FINALITE_SOCIETALE' | 'CODE_APE' | 'TYPE_ROLE' | 'CIVILITE' | 'SITUATION_MATRIMONIALE' | 'SITUATION_PROFESSIONNELLE' | 'TYPE_REVENU' | 'NIVEAU_ETUDE' | 'BENEFICIAIRE_MINIMA_SOCIAUX' | 'ZONAGE_EPCI' | 'MOTIF_ABANDON_ACCES_PLUS' | 'PRESCRIPTION_CAS_SORTIE'}
   * @memberof ReferenceResourceApiGetReferenceByCode
   */
  readonly referenceCode:
    | "STATUT_JURIDIQUE"
    | "SECTEUR_ACTIVITE"
    | "STATUT_COOPERATIF"
    | "AGREMENT_ESUS"
    | "RECOMMANDEUR"
    | "TYPE_RECOMMANDEUR"
    | "PHASE_PROJET"
    | "TERRITOIRE_FRAGILE"
    | "OBJET_FINANCEMENT"
    | "OBJET_PROJET"
    | "FINALITE_SOCIETALE"
    | "CODE_APE"
    | "TYPE_ROLE"
    | "CIVILITE"
    | "SITUATION_MATRIMONIALE"
    | "SITUATION_PROFESSIONNELLE"
    | "TYPE_REVENU"
    | "NIVEAU_ETUDE"
    | "BENEFICIAIRE_MINIMA_SOCIAUX"
    | "ZONAGE_EPCI"
    | "MOTIF_ABANDON_ACCES_PLUS"
    | "PRESCRIPTION_CAS_SORTIE"
}

/**
 * ReferenceResourceApi - object-oriented interface
 * @export
 * @class ReferenceResourceApi
 * @extends {BaseAPI}
 */
export class ReferenceResourceApi extends BaseAPI {
  /**
   *
   * @summary Get a reference by its code
   * @param {ReferenceResourceApiGetReferenceByCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferenceResourceApi
   */
  public getReferenceByCode(
    requestParameters: ReferenceResourceApiGetReferenceByCodeRequest,
    options?: AxiosRequestConfig
  ) {
    return ReferenceResourceApiFp(this.configuration)
      .getReferenceByCode(requestParameters.referenceCode, options)
      .then(request => request(this.axios, this.basePath))
  }
}
