/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { ApiAddressSearch } from "../model"
/**
 * AddressResourceApi - axios parameter creator
 * @export
 */
export const AddressResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Search an address in mainland France
     * @param {string} queryText
     * @param {number} [timeout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAddress: async (
      queryText: string,
      timeout?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'queryText' is not null or undefined
      assertParamExists("searchAddress", "queryText", queryText)
      const localVarPath = `/addresses/adresse`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (queryText !== undefined) {
        localVarQueryParameter["queryText"] = queryText
      }

      if (timeout !== undefined) {
        localVarQueryParameter["timeout"] = timeout
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Search an address overseas
     * @param {string} queryText
     * @param {number} [timeout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAddressOverseas: async (
      queryText: string,
      timeout?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'queryText' is not null or undefined
      assertParamExists("searchAddressOverseas", "queryText", queryText)
      const localVarPath = `/addresses/adresseOutremer`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (queryText !== undefined) {
        localVarQueryParameter["queryText"] = queryText
      }

      if (timeout !== undefined) {
        localVarQueryParameter["timeout"] = timeout
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Search a city given a label
     * @param {string} queryText
     * @param {number} [timeout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCity: async (
      queryText: string,
      timeout?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'queryText' is not null or undefined
      assertParamExists("searchCity", "queryText", queryText)
      const localVarPath = `/addresses/city`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (queryText !== undefined) {
        localVarQueryParameter["queryText"] = queryText
      }

      if (timeout !== undefined) {
        localVarQueryParameter["timeout"] = timeout
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * AddressResourceApi - functional programming interface
 * @export
 */
export const AddressResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AddressResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Search an address in mainland France
     * @param {string} queryText
     * @param {number} [timeout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAddress(
      queryText: string,
      timeout?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiAddressSearch>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAddress(
        queryText,
        timeout,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Search an address overseas
     * @param {string} queryText
     * @param {number} [timeout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAddressOverseas(
      queryText: string,
      timeout?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiAddressSearch>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAddressOverseas(
        queryText,
        timeout,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Search a city given a label
     * @param {string} queryText
     * @param {number} [timeout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchCity(
      queryText: string,
      timeout?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiAddressSearch>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchCity(
        queryText,
        timeout,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * AddressResourceApi - factory interface
 * @export
 */
export const AddressResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AddressResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Search an address in mainland France
     * @param {string} queryText
     * @param {number} [timeout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAddress(
      queryText: string,
      timeout?: number,
      options?: any
    ): AxiosPromise<Array<ApiAddressSearch>> {
      return localVarFp
        .searchAddress(queryText, timeout, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Search an address overseas
     * @param {string} queryText
     * @param {number} [timeout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAddressOverseas(
      queryText: string,
      timeout?: number,
      options?: any
    ): AxiosPromise<Array<ApiAddressSearch>> {
      return localVarFp
        .searchAddressOverseas(queryText, timeout, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Search a city given a label
     * @param {string} queryText
     * @param {number} [timeout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCity(
      queryText: string,
      timeout?: number,
      options?: any
    ): AxiosPromise<Array<ApiAddressSearch>> {
      return localVarFp
        .searchCity(queryText, timeout, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for searchAddress operation in AddressResourceApi.
 * @export
 * @interface AddressResourceApiSearchAddressRequest
 */
export interface AddressResourceApiSearchAddressRequest {
  /**
   *
   * @type {string}
   * @memberof AddressResourceApiSearchAddress
   */
  readonly queryText: string

  /**
   *
   * @type {number}
   * @memberof AddressResourceApiSearchAddress
   */
  readonly timeout?: number
}

/**
 * Request parameters for searchAddressOverseas operation in AddressResourceApi.
 * @export
 * @interface AddressResourceApiSearchAddressOverseasRequest
 */
export interface AddressResourceApiSearchAddressOverseasRequest {
  /**
   *
   * @type {string}
   * @memberof AddressResourceApiSearchAddressOverseas
   */
  readonly queryText: string

  /**
   *
   * @type {number}
   * @memberof AddressResourceApiSearchAddressOverseas
   */
  readonly timeout?: number
}

/**
 * Request parameters for searchCity operation in AddressResourceApi.
 * @export
 * @interface AddressResourceApiSearchCityRequest
 */
export interface AddressResourceApiSearchCityRequest {
  /**
   *
   * @type {string}
   * @memberof AddressResourceApiSearchCity
   */
  readonly queryText: string

  /**
   *
   * @type {number}
   * @memberof AddressResourceApiSearchCity
   */
  readonly timeout?: number
}

/**
 * AddressResourceApi - object-oriented interface
 * @export
 * @class AddressResourceApi
 * @extends {BaseAPI}
 */
export class AddressResourceApi extends BaseAPI {
  /**
   *
   * @summary Search an address in mainland France
   * @param {AddressResourceApiSearchAddressRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressResourceApi
   */
  public searchAddress(
    requestParameters: AddressResourceApiSearchAddressRequest,
    options?: AxiosRequestConfig
  ) {
    return AddressResourceApiFp(this.configuration)
      .searchAddress(requestParameters.queryText, requestParameters.timeout, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Search an address overseas
   * @param {AddressResourceApiSearchAddressOverseasRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressResourceApi
   */
  public searchAddressOverseas(
    requestParameters: AddressResourceApiSearchAddressOverseasRequest,
    options?: AxiosRequestConfig
  ) {
    return AddressResourceApiFp(this.configuration)
      .searchAddressOverseas(requestParameters.queryText, requestParameters.timeout, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Search a city given a label
   * @param {AddressResourceApiSearchCityRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressResourceApi
   */
  public searchCity(
    requestParameters: AddressResourceApiSearchCityRequest,
    options?: AxiosRequestConfig
  ) {
    return AddressResourceApiFp(this.configuration)
      .searchCity(requestParameters.queryText, requestParameters.timeout, options)
      .then(request => request(this.axios, this.basePath))
  }
}
