import React, { CSSProperties, FC, useEffect, useState } from "react"
import { AddressUpdateRequest, ApiAddressSearch } from "../../client/backend-client/generated"
import { AutoComplete, AutoCompleteProps, Col, Flex, Row, Space, Typography } from "antd"
import { useSearchCity } from "../../queries/AddressQueries"
import { useDebouncedCallback } from "use-debounce"
import { SEARCH_DEBOUNCE_DEFAULT_DELAY } from "../../utils/DebounceUtils"
import { EnvironmentOutlined } from "@ant-design/icons"
import { DefaultOptionType } from "antd/es/select"

const { Text } = Typography

interface CityOption extends DefaultOptionType {
  label: React.ReactNode
  value?: string | number | null
  children?: Omit<DefaultOptionType, "children">[]
  address?: ApiAddressSearch
}

export const CityAutoComplete: FC<{
  onChange: (value: AddressUpdateRequest) => void
  allowClear?: boolean
  disabled?: boolean
  style?: CSSProperties
  defaultValue: string | undefined
}> = ({ onChange, allowClear, disabled, style, defaultValue }) => {
  const [searchText, setSearchText] = useState<string>("")
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([])
  const [selectedCity, setSelectedCity] = useState<ApiAddressSearch>()

  const { isLoading: areCitiesLoading, data: cityQuery } = useSearchCity(searchText)

  const debouncedOnSearchTextChange = useDebouncedCallback((value?: string) => {
    setSearchText(value && value.trim() ? value : "")
  }, SEARCH_DEBOUNCE_DEFAULT_DELAY)

  useEffect(() => {
    if (!areCitiesLoading && cityQuery) {
      setOptions(cityQuery.map(renderCityOption))
    }
  }, [cityQuery])

  const onSelect = (CityOption: CityOption) => {
    setSelectedCity(CityOption.address)
  }

  useEffect(() => {
    if (selectedCity) {
      onChange({
        adresse1: selectedCity.adresse1,
        codePostal: selectedCity.codePostal,
        ville: selectedCity.ville,
        codeCommune: selectedCity.codeCommune,
        departementCode: selectedCity.departementCode,
        code: selectedCity.code
      })
    }
  }, [selectedCity])

  const renderCityOption = (address: ApiAddressSearch): CityOption => ({
    value: address.ville,
    address: address,
    label: (
      <>
        <Flex align="center" justify="start" key={`${address.ville}-${address.departementCode}`}>
          <Col span={1} style={{ textAlign: "center" }}>
            <EnvironmentOutlined />
          </Col>
          <Col span={11}>
            <Space direction="vertical">
              <Text>{`${address.ville} (${address.departementCode})`}</Text>
            </Space>
          </Col>
        </Flex>
      </>
    )
  })

  return (
    <Row gutter={[16, 16]} justify={"start"} align={"middle"}>
      <Col span={24}>
        <AutoComplete
          searchValue={searchText}
          options={options}
          onSearch={debouncedOnSearchTextChange}
          onSelect={(_, option: CityOption) => onSelect(option)}
          style={style ?? { width: "100%" }}
          allowClear={allowClear}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      </Col>
    </Row>
  )
}
