import { useAppContext } from "../config/context"
import { useQuery } from "@tanstack/react-query"
import { extract } from "../client/backend-client/AxiosUtils"
import { useHeaders } from "./axios-headers"
import { AddressUpdateRequest } from "../client/backend-client/generated"

export const useSearchAddress = (search: string, isOverseas: boolean = false) => {
  const { addressResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data } = useQuery({
    queryKey: ["address-search", search, isOverseas],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return isOverseas
        ? addressResourceApi.searchAddressOverseas({ queryText: search }, { headers }).then(extract)
        : addressResourceApi.searchAddress({ queryText: search }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la recherche de l'adresse`
    }
  })

  return {
    isLoading,
    data
  }
}

export const useSearchCity = (search: string) => {
  const { addressResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data } = useQuery({
    queryKey: ["address-search", search],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return addressResourceApi.searchCity({ queryText: search }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la recherche de la ville`
    }
  })

  return {
    isLoading,
    data
  }
}

export const areAddressEqual = (
  addressA: AddressUpdateRequest | undefined,
  addressB: AddressUpdateRequest | undefined
) =>
  addressA?.adresse1?.trim() === addressB?.adresse1?.trim() &&
  addressA?.adresse2?.trim() === addressB?.adresse2?.trim() &&
  addressA?.codePostal?.trim() === addressB?.codePostal?.trim() &&
  addressA?.ville?.trim() === addressB?.ville?.trim() &&
  addressA?.codeCommune?.trim() === addressB?.codeCommune?.trim() &&
  addressA?.departementCode?.trim() === addressB?.departementCode?.trim()
