import Table from "antd/es/table"
import { TableData } from "../../../utils/TableUtils"
import { FC, useEffect, useState } from "react"
import React from "react"
import ColumnGroup from "antd/es/table/ColumnGroup"
import Column from "antd/es/table/Column"
import { ExpertiseProjetRecap } from "../../../client/backend-client/generated"
import { InputNumber } from "antd"
import { useDebouncedCallback } from "use-debounce"
import { getFlushEffectCallback, UPDATE_DEBOUNCE_DEFAULT_DELAY } from "../../../utils/DebounceUtils"
import _ from "lodash"

export const EtpDetailTable: FC<{
  employmentData?: ExpertiseProjetRecap
  onChangeEtp: (value: ExpertiseProjetRecap) => void
}> = ({ employmentData, onChangeEtp }) => {
  const [etpData, setEtpData] = useState(employmentData)

  const debouncedOnEtpChange = useDebouncedCallback(() => {
    if (!!etpData && !_.isEqual(etpData, employmentData)) {
      onChangeEtp(etpData)
    }
  }, UPDATE_DEBOUNCE_DEFAULT_DELAY)

  useEffect(debouncedOnEtpChange, [etpData])
  useEffect(getFlushEffectCallback(debouncedOnEtpChange), [debouncedOnEtpChange])

  const buildEtpTableDataSource = (): TableData[] => {
    return [
      {
        key: "insertion",
        rowTitle: "Insertion / Contrats aidés",
        beforeCdi: (
          <InputNumber
            min={0}
            value={etpData?.insertionContratsAidesCdiAvant ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, insertionContratsAidesCdiAvant: v ?? 0 })
            }
          />
        ),
        beforeCdd: <></>,
        afterCdi: (
          <InputNumber
            min={0}
            value={etpData?.insertionContratsAidesCdiApres ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, insertionContratsAidesCdiApres: v ?? 0 })
            }
          />
        ),
        afterCdd: <></>
      },
      {
        key: "handicape",
        rowTitle: "Handicapés",
        beforeCdi: (
          <InputNumber
            min={0}
            value={etpData?.handicapesCdiAvant ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, handicapesCdiAvant: v ?? 0 })
            }
          />
        ),
        beforeCdd: (
          <InputNumber
            min={0}
            value={etpData?.handicapesCddAvant ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, handicapesCddAvant: v ?? 0 })
            }
          />
        ),
        afterCdi: (
          <InputNumber
            min={0}
            value={etpData?.handicapesCdiApres ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, handicapesCdiApres: v ?? 0 })
            }
          />
        ),
        afterCdd: (
          <InputNumber
            min={0}
            value={etpData?.handicapesCddApres ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, handicapesCddApres: v ?? 0 })
            }
          />
        )
      },
      {
        key: "salaries",
        rowTitle: "Salariés de droit commun",
        beforeCdi: (
          <InputNumber
            min={0}
            value={etpData?.salarieDroitCommunCdiAvant ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, salarieDroitCommunCdiAvant: v ?? 0 })
            }
          />
        ),
        beforeCdd: (
          <InputNumber
            min={0}
            value={etpData?.salarieDroitCommunCddAvant ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, salarieDroitCommunCddAvant: v ?? 0 })
            }
          />
        ),
        afterCdi: (
          <InputNumber
            min={0}
            value={etpData?.salarieDroitCommunCdiApres ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, salarieDroitCommunCdiApres: v ?? 0 })
            }
          />
        ),
        afterCdd: (
          <InputNumber
            min={0}
            value={etpData?.salarieDroitCommunCddApres ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, salarieDroitCommunCddApres: v ?? 0 })
            }
          />
        )
      },
      {
        key: "nonSalaries",
        rowTitle: "Non salariés",
        beforeCdi: (
          <InputNumber
            min={0}
            value={etpData?.nonSalariesCdiAvant ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, nonSalariesCdiAvant: v ?? 0 })
            }
          />
        ),
        beforeCdd: <></>,
        afterCdi: (
          <InputNumber
            min={0}
            value={etpData?.nonSalariesCdiApres ?? 0}
            onChange={(v: number | null) =>
              setEtpData({ ...etpData, nonSalariesCdiApres: v ?? 0 })
            }
          />
        ),
        afterCdd: <></>
      }
    ]
  }
  return (
    <Table dataSource={buildEtpTableDataSource()} rowKey={record => record.key}>
      <Column key="rowTitle" title="Tableau des ETPs" dataIndex="rowTitle"></Column>
      <ColumnGroup title="ETP avant">
        <Column title="CDI" dataIndex="beforeCdi" key="beforeCdi" />
        <Column title="CDD" dataIndex="beforeCdd" key="beforeCdd" />
      </ColumnGroup>
      <ColumnGroup title="ETP après">
        <Column title="CDI" dataIndex="afterCdi" key="afterCdi" />
        <Column title="CDD" dataIndex="afterCdd" key="afterCdd" />
      </ColumnGroup>
    </Table>
  )
}
