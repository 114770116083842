import React, { CSSProperties, FC, useEffect, useState } from "react"
import { SelectItem } from "./AppMetierSelect"
import { ReferenceCode, useGetReference } from "../../queries/ReferenceQueries"
import { CodeLibelle } from "../../client/backend-client/generated"
import { ConfigProvider, Select } from "antd"

export const codeLibelleToSelectItem = (
  value: CodeLibelle,
  includeCodeInLabel: boolean = false
): SelectItem<string> => ({
  value: value.code,
  label: includeCodeInLabel ? `${value.code} - ${value.libelle}` : value.libelle
})

export const ReferenceSelect: FC<{
  referenceCode: ReferenceCode
  codeLibelle?: CodeLibelle
  prescriberType?: CodeLibelle
  onChange?: (value: CodeLibelle | undefined) => void
  status?: "" | "warning" | "error"
  loading?: boolean
  disabled?: boolean
  allowClear?: boolean
  style?: CSSProperties
}> = ({
  referenceCode,
  codeLibelle,
  prescriberType,
  onChange,
  status,
  loading,
  disabled,
  allowClear,
  style
}) => {
  const { isLoading: areReferencesLoading, data: references } = useGetReference(referenceCode)
  const includeCodeInLabel = referenceCode === ReferenceCode.CODE_APE

  const getReferenceOptions = (references: CodeLibelle[]) =>
    references.map(reference => codeLibelleToSelectItem(reference, includeCodeInLabel))

  const [referenceOptions, setReferenceOptions] = useState<SelectItem<string>[]>(
    references ? getReferenceOptions(references) : []
  )

  useEffect(() => {
    const filteredReferences = prescriberType
      ? references?.filter(reference => reference.typeRecommandeurCode === prescriberType.code)
      : references
    setReferenceOptions(filteredReferences ? getReferenceOptions(filteredReferences) : [])
    if (prescriberType && onChange && filteredReferences && filteredReferences.length === 1) {
      onChange(filteredReferences[0])
    }
  }, [references, prescriberType, codeLibelle])

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorTextDisabled: "rgba(0, 0, 0, 0.50)"
          }
        }}
      >
        {references && (
          <Select<SelectItem<string>>
            value={codeLibelle && codeLibelleToSelectItem(codeLibelle, includeCodeInLabel)}
            labelInValue
            style={style ?? { width: "100%" }}
            onChange={(item: SelectItem<string>) => {
              if (onChange) {
                const newCodeLibelle = item
                  ? references.find(reference => reference.code === item.value)
                  : undefined
                onChange(newCodeLibelle)
              }
            }}
            status={status}
            loading={areReferencesLoading || loading}
            disabled={disabled}
            allowClear={allowClear}
            options={referenceOptions}
          />
        )}
      </ConfigProvider>
    </>
  )
}
