import React, { FC, useContext, useEffect, useState } from "react"
import { Alert, Card, List, Space, Tooltip, Typography } from "antd"
import { InvolvementCheckWrapperContext } from "./InvolvementCheckPageWrapper"
import { AppMetierTitle } from "../../components/AppMetierTitle/AppMetierTitle"
import { useGetInvolvementProfiles, useUpdateInvolvementResult } from "./InvolvementCheckQueries"
import {
  getInvolvementProfileLabelFromResult,
  getInvolvementScoreOptions,
  INVOLVEMENT_CATEGORY_GROUP_RECORD,
  INVOLVEMENT_PROFILE_V2_RECORD,
  InvolvementScoreInputType,
  InvolvementVersion
} from "./InvolvementUtils"
import { EditorTextInput } from "../../components/Editor/EditorTextInput"
import { NoteContext } from "../NotePage"
import { ScoreInput } from "../../components/ScoreInput/ScoreInput"
import _ from "lodash"
import { useUpdateNote } from "../../queries/NoteQueries"
import { areSameOutputData, toOutputData } from "../../components/Editor/utilities"
import { ResultInvolvementProfileV2Enum } from "../../client/backend-client/generated"
import { InvolvementChart } from "./InvolvementChart"
import { useDebouncedCallback } from "use-debounce"
import { getFlushEffectCallback, UPDATE_DEBOUNCE_DEFAULT_DELAY } from "../../utils/DebounceUtils"
import { InfoCircleOutlined } from "@ant-design/icons"
import { blue } from "@ant-design/colors"

const { Paragraph, Text } = Typography

export const InvolvementCheckResultCard: FC<{
  version: InvolvementVersion
}> = ({ version }) => {
  const { note, noteId } = useContext(NoteContext)
  const { involvementCheckResult, forceV2 } = useContext(InvolvementCheckWrapperContext)

  const noteMutation = useUpdateNote(noteId)
  const involvementCheckResultMutation = useUpdateInvolvementResult()

  const initialComment = toOutputData(note.commentRevelateur)
  const [involvementComment, setInvolvementComment] = useState(initialComment)
  const [personalInvolvement, setPersonalInvolvement] = useState(
    involvementCheckResult.personalInvolvement
  )

  const debouncedCommentUpdate = useDebouncedCallback(() => {
    if (!areSameOutputData(involvementComment, initialComment)) {
      noteMutation.mutate({
        noteId,
        commentRevelateur: JSON.stringify(involvementComment)
      })
    }
  }, UPDATE_DEBOUNCE_DEFAULT_DELAY)

  useEffect(debouncedCommentUpdate, [involvementComment])
  useEffect(getFlushEffectCallback(debouncedCommentUpdate), [debouncedCommentUpdate])

  useEffect(() => {
    if (!_.isEqual(personalInvolvement, involvementCheckResult.personalInvolvement)) {
      involvementCheckResultMutation.mutate({
        noteId: noteId,
        resultSaveRequest: {
          personalInvolvement: personalInvolvement,
          forceV2: forceV2
        }
      })
    }
  }, [personalInvolvement])

  return (
    <Card style={{ minWidth: "350px" }}>
      <Space direction="vertical" size="large">
        {version === 1 ? <InvolvementCheckProfileRecapV1 /> : <InvolvementCheckProfileRecapV2 />}
        <Space direction="vertical" align={"center"}>
          {(version == 1 || !!involvementCheckResult.involvementProfileV2) && (
            <InvolvementChart version={version} />
          )}
          <Tooltip
            placement="bottom"
            title="Disponibilité pour s'engager aux côtés de France Active (parrainage, partage de pratiques, communauté d’entrepreneurs, épargne solidaire, promotion de France Active, etc.)"
          >
            <Text strong>
              Implication du porteur de projet vis-à-vis de l’association territoriale
            </Text>
          </Tooltip>
          <ScoreInput<number>
            score={involvementCheckResult.personalInvolvement}
            onChange={setPersonalInvolvement}
            options={getInvolvementScoreOptions(InvolvementScoreInputType.SCORE, 1)}
          />
        </Space>
        <Space direction="vertical" className="full-width-space">
          <Text strong>Commentaire</Text>
          <EditorTextInput
            initialValue={involvementComment}
            onChange={value => setInvolvementComment(value)}
          />
        </Space>
      </Space>
    </Card>
  )
}

const InvolvementCheckProfileRecapV1: FC = () => {
  const { involvementCheckResult } = useContext(InvolvementCheckWrapperContext)
  const { isLoading, data: profileConfigs } = useGetInvolvementProfiles()
  return (
    <Space direction="vertical">
      <Text strong>Profil d’engagement :</Text>
      <AppMetierTitle level={4}>
        {getInvolvementProfileLabelFromResult(involvementCheckResult, profileConfigs, isLoading)}
      </AppMetierTitle>
    </Space>
  )
}

const InvolvementCheckProfileRecapV2: FC = () => {
  const { involvementCheckResult } = useContext(InvolvementCheckWrapperContext)

  return (
    <>
      {involvementCheckResult.involvementProfileV2 ? (
        <>
          <Text strong>Profil d’engagement :</Text>
          <AppMetierTitle
            level={4}
            style={{
              marginTop: "0.5em",
              color:
                involvementCheckResult.involvementProfileV2 ===
                ResultInvolvementProfileV2Enum.NEGATIVE_IMPACT
                  ? "var(--main-red-2)"
                  : "var(--main-green-1)"
            }}
          >
            {INVOLVEMENT_PROFILE_V2_RECORD[involvementCheckResult.involvementProfileV2]}
          </AppMetierTitle>
        </>
      ) : (
        <Alert
          message={
            <>
              <div style={{ display: "flex", margin: "5px 0px" }}>
                <div>
                  <InfoCircleOutlined style={{ marginRight: "5px", color: blue.primary }} />
                </div>
                <Text strong>Formulaire incomplet</Text>
              </div>
              <Paragraph>
                Renseignez l'ensemble des critères pour calculer le profil d'engagement.
              </Paragraph>
              <Paragraph>
                Le barème de rémunération de l'AT est impacté par la saisie du révélateur.
              </Paragraph>
            </>
          }
        />
      )}
      {involvementCheckResult.involvementProfileV2 &&
        involvementCheckResult.cornerstoneCategories.length > 0 && (
          <List
            size="small"
            bordered
            dataSource={involvementCheckResult.cornerstoneCategories}
            renderItem={category => {
              const categoryConfig = INVOLVEMENT_CATEGORY_GROUP_RECORD[category]
              return (
                <List.Item>
                  <Text style={{ color: categoryConfig.color }}>{categoryConfig.label}</Text>
                </List.Item>
              )
            }}
          />
        )}
    </>
  )
}
