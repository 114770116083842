import React, { FC, useContext } from "react"
import { NoteContext } from "../NotePage"
import { Route, Routes } from "react-router-dom"
import { Breadcrumb } from "antd"
import { HomeOutlined } from "@ant-design/icons"
import { ProjectPageWrapper } from "./project/ProjectPageWrapper"
import { StructurePageWrapper } from "./structure/StructurePageWrapper"
import { HolderPageWrapper } from "./holder/HolderPageWrapper"

export const INFOS_PAGE_NAME = "Informations générales"

export const InfosPageWrapper: FC = () => {
  const { projectName, baseNotePath } = useContext(NoteContext)

  return (
    <Routes>
      <Route
        path={"project"}
        element={
          <>
            <Breadcrumb
              className="breadcrumb"
              items={[
                { title: <HomeOutlined /> },
                {
                  href: `${baseNotePath}/presentation`,
                  title: projectName
                },
                { title: INFOS_PAGE_NAME },
                { title: "Projet" }
              ]}
            />
            {<ProjectPageWrapper />}
          </>
        }
      />
      <Route
        path={"structure"}
        element={
          <>
            <Breadcrumb
              className="breadcrumb"
              items={[
                { title: <HomeOutlined /> },
                {
                  href: `${baseNotePath}/presentation`,
                  title: projectName
                },
                { title: INFOS_PAGE_NAME },
                { title: "Structure" }
              ]}
            />
            {<StructurePageWrapper />}
          </>
        }
      />
      <Route
        path={"holders"}
        element={
          <>
            <Breadcrumb
              className="breadcrumb"
              items={[
                { title: <HomeOutlined /> },
                {
                  href: `${baseNotePath}/presentation`,
                  title: projectName
                },
                { title: INFOS_PAGE_NAME },
                { title: "Porteurs" }
              ]}
            />
            {<HolderPageWrapper />}
          </>
        }
      />
    </Routes>
  )
}

export default InfosPageWrapper
